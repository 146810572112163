<template>
	<router-view></router-view>
</template>

<script>
	export default {
		name: 'app',
		components: {

		},
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>
<style lang="less">
	* {
		margin: 0;
		padding: 0;
		font-family: 'Microsoft YaHei', Arial, Helvetica, sans-serif, '宋体'; // font-size: 16px;
	}

	a {
		text-decoration: none;
	}

	html {
		width: 100%;
		background-color: #F2F4FF;
		max-height: 100%;
	}

	body {}
</style>
