import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import yewubanli from '../views/yewubanli.vue'
import caozuojilu from '../views/caozuojilu.vue'
import abouts from '../views/abouts.vue'
import logins from '../views/login.vue'
import notice from '../views/notice.vue'
import index from '../views/index.vue'
import outtime from '../views/outtime.vue'

Vue.use(Router)

import VueRouter from 'vue-router'

Vue.use(VueRouter)
    // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    routes: [{
            path: '/',
            meta: {
                title: "邯郸市机场贵宾室VIP会员管理系统",
            },
            name: 'logins',
            component: logins
        }, {
            path: '/index',
            name: 'index',
            meta: {
                title: "邯郸市机场贵宾室VIP会员管理系统",
            },
            component: index
        }, {
            path: '/home',
            redirect: '/abouts',
            name: 'home',
            meta: {
                title: "邯郸市机场贵宾室VIP会员管理系统",
            },
            component: Home,
            children: [{
                path: '/abouts',
                name: 'abouts',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: abouts
            }, {
                path: '/yewubanli',
                name: 'yewubanli',
                component: yewubanli
            }, {
                path: '/caozuojilu',
                name: 'caozuojilu',
                component: caozuojilu
            },{
                path: '/outtime',
                name: 'outtime',
                component: outtime
            }, {
                path: '/notice',
                name: 'notice',
                component: notice
            }, ]
        },

        // {
        // 	path: '/yewubanli',
        // 	name: 'yewubanli',
        // 	component: yewubanli
        // }, {
        // 	path: '/caozuojilu',
        // 	name: 'caozuojilu',
        // 	component: caozuojilu
        // },

    ]
})