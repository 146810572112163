<template>
	<div id="yewubanli">
		<el-dialog @close="close_change_dialog" title="换卡成功" :visible.sync="changecard_dialog" width="580px">
			<div id="" style="text-align: center; margin-bottom: 2.5em; position: relative">
				<div class="title">
					恭喜您换卡成功，您的新卡号为：{{ change_card_number }}
				</div>
				<span
					:class="change_card.card_id == '1'? 'white_txt': change_card.card_id == '2'? 'gold_txt': 'gold_txt'">NO.{{ change_card_number }}</span>
				<img :src="change_card_img" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="close_change_dialog">确 定
				</el-button>
			</span>
		</el-dialog>
		<el-dialog @close="close_binding_card_dialog" title="绑卡成功" :visible.sync="binding_card_dialog" width="580px">
			<div id="" style="text-align: center; margin-bottom: 2.5em; position: relative">
				<div class="title">
					恭喜您绑卡成功，您的卡号为：{{ binding_card_number }}
				</div>
				<span v-if="binding_card.is_give==1">赠送卡</span>
				<span
					:class="binding_card.card_id == '1'? 'white_txt': (binding_card.card_id == '2'? 'gold_txt': 'gold_txt')">NO.{{ binding_card_number }}</span>
				<img :src="binding_card_img" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="close_binding_card_dialog">确 定
				</el-button>
			</span>
		</el-dialog>
		<!-- 赠送卡成功 -->
		<el-dialog @close="close_give_card_dialog" title="赠送卡成功" :visible.sync="give_card_dialog" width="580px">
			<div id="" style="text-align: center; margin-bottom: 2.5em; position: relative">
				<div class="title">
					恭喜您赠送卡成功，您的卡号为：{{ give_card_number }}
				</div>
				<span
					:class="give_card.card_id == '1'? 'white_txt': (give_card.card_id == '2'? 'gold_txt': 'gold_txt')">NO.{{ give_card_number }}</span>
				<img :src="give_card_img" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="close_give_card_dialog">确 定
				</el-button>
			</span>
		</el-dialog>
		<el-dialog @close="close_binding_register_vip_dialog" title="注册成功" :visible.sync="register_vip_dialog"
			width="580px">
			<div v-show="ruleForm_number" id="" style="text-align: center; margin-bottom: 2.5em; position: relative">
				<div class="title">
					恭喜您注册成功，您的卡号为：{{ ruleForm_number }}
				</div>
				<span :class="
            ruleForm.card_type == '1'
              ? 'white_txt'
              : ruleForm.card_type == '2'
              ? 'gold_txt'
              : 'gold_txt'
          ">NO.{{ ruleForm_number }}</span>
				<img :src="
            ruleForm_img
          " />
			</div>
			<div v-show="!ruleForm_number" id="" style="text-align: center; margin-bottom: 2.5em; position: relative">
				<div class="title">
					恭喜您注册成功
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="close_binding_register_vip_dialog">确 定
				</el-button>
			</span>
		</el-dialog>
		<!-- 预约贵宾室一人多卡弹框 -->
		<el-dialog @close="close_subscribe_many_people" title="请选择会员卡" :visible.sync="subscribe_many_people"
			width="580px">
			<div id="" v-for="item,index in subscribe" :key="index" style="">
				<div style="text-align: center; padding: 2.5em 0; position: relative;">
					<span v-if="item.is_give==1" class="time2">赠送卡</span>
					<span :class="
				  item.card_name == '纪念卡'
				    ? 'white_txt'
				    : (item.card_name == '黄金卡'
				    ? 'gold_txt'
				    : 'gold_txt') 
				" style="top: 9.6em;">NO.{{ item.card_no }}</span>

					<img :src="item.card_img" style="cursor: pointer;" @click="subscribe_many_people_choose(index)" />
					<div class="time">
						<span v-if="item.card_type == 1"
							style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ item.surplus }}</span>
						<span v-else style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ item.surplus }}</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 充值一人多卡弹框 -->
		<el-dialog @close="close_recharge_many_people" title="请选择会员卡" :visible.sync="recharge_many_people"
			width="580px">
			<div id="" v-for="item,index in Recharge" :key="index">
				<div style="text-align: center; padding: 2.5em 0; position: relative;">
					<span v-if="item.is_give==1" class="time2">赠送卡</span>
					<span :class="
				  item.card_name == '纪念卡'
				    ? 'white_txt'
				    : (item.card_name == '黄金卡'
				    ? 'gold_txt'
				    : 'gold_txt') 
				" style="top: 9.6em;">NO.{{ item.card_no }}</span>
					<img :src="item.card_img" style="cursor: pointer;" @click="recharge_many_people_choose(index)" />
					<div class="time">
						<span v-if="item.card_type == 1"
							style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ item.surplus }}</span>
						<span v-else style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ item.surplus }}</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 挂失一人多卡弹框 -->
		<el-dialog @close="close_report_the_loss_many_people" title="请选择会员卡" :visible.sync="report_the_loss_many_people"
			width="580px">
			<div id="" v-for="item,index in report_the_loss" :key="index">
				<div style="text-align: center; padding: 2.5em 0; position: relative;">
					<span v-if="item.is_give==1" class="time2">赠送卡</span>
					<span :class="
				  item.card_name == '纪念卡'
				    ? 'white_txt'
				    : (item.card_name == '黄金卡'
				    ? 'gold_txt'
				    : 'gold_txt') 
				" style="top: 9.6em;">NO.{{ item.card_no }}</span>
					<img :src="item.card_img" style="cursor: pointer;"
						@click="report_the_loss_many_people_choose(index)" />
					<div class="time">
						<span v-if="item.card_type == 1"
							style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ item.surplus }}</span>
						<span v-else style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ item.surplus }}</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 挂失一人多卡弹框 -->
		<el-dialog @close="close_change_card_many_people" title="请选择会员卡" :visible.sync="change_card_many_people"
			width="580px">
			<div id="" v-for="item,index in change_card" :key="index">
				<div style="text-align: center; padding: 2.5em 0; position: relative;">
					<span v-if="item.is_give==1" class="time2">赠送卡</span>
					<span :class="
				  item.card_name == '纪念卡'
				    ? 'white_txt'
				    : (item.card_name == '黄金卡'
				    ? 'gold_txt'
				    : 'gold_txt') 
				" style="top: 9.6em;">NO.{{ item.card_no }}</span>
					<img :src="item.card_img" style="cursor: pointer;" @click="change_card_many_people_choose(index)" />
					<div class="time">
						<span v-if="item.card_type == 1"
							style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ item.surplus }}</span>
						<span v-else style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ item.surplus }}</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<el-tabs class="toptabs" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="注册会员" name="0">
				<el-form class="zhucehuiyuan" ref="ruleForm" label-position="top" label-width="80px" :model="ruleForm">
					<div class="title">
						<div class="roundblue"></div>
						<span style="display: inline-block;vertical-align: middle;font-size: 0.875em;">基本信息</span>
					</div>
					<el-form-item label="姓名">
						<el-input placeholder="请输入姓名" v-model="ruleForm.real_name"></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<el-radio class="sex" v-model="ruleForm.gender" label="1" border>男士</el-radio>
						<el-radio class="sex" v-model="ruleForm.gender" label="2" border>女士</el-radio>
					</el-form-item>
					<div class="flex">
						<!-- <div class="hei" style="margin-right: 5em">
							<el-form-item label="密码">
								<el-input placeholder="请输入密码 ( 不填写默认为身份证后6位 )" v-model="ruleForm.password"></el-input>
							</el-form-item>
						</div> -->
						<div style="margin-right: 5em">
							<el-form-item label="手机号">
								<el-input placeholder="请输入手机号" v-model="ruleForm.phone"></el-input>
							</el-form-item>
						</div>
						<div class="hei">
							<el-form-item label="身份证号">
								<el-input placeholder="请输入身份证号" v-model="ruleForm.Id_card"></el-input>
							</el-form-item>
						</div>
						<!-- <div>
							<el-form-item label="身份标签">
								<el-input placeholder="请输入身份标签" v-model="ruleForm.Id_tag"></el-input>
							</el-form-item>
						</div> -->
					</div>
					<div class="title">
						<div class="roundblue"></div>
						<span style="
                display: inline-block;
                vertical-align: middle;
                font-size: 0.875em;
              ">选填信息</span>
					</div>
					<div class="flex">
						<div class="hei" style="margin-right: 5em">
							<el-form-item label="工作单位">
								<el-input placeholder="请输入工作单位" v-model="ruleForm.work_units"></el-input>
							</el-form-item>
						</div>
						<div>
							<el-form-item label="爱好">
								<el-input placeholder="请输入爱好" v-model="ruleForm.hobby"></el-input>
							</el-form-item>
						</div>
						<div class="hei">
							<el-form-item label="车牌号">
								<el-input placeholder="请输入车牌号" v-model="ruleForm.plateNO"></el-input>
							</el-form-item>
						</div>
						<div>
							<el-form-item label="饮食爱好">
								<el-input placeholder="请输入饮食爱好" v-model="ruleForm.food_preference"></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="title">
						<div class="roundblue"></div>
						<span style="
                display: inline-block;
                vertical-align: middle;
                font-size: 0.875em;
              ">绑卡</span>
					</div>
					<div class="flex">
						<div style="margin-right: 5em">
							<el-form-item label="卡号">
								<el-input placeholder="请输入卡号" v-model="ruleForm.card_no">
								</el-input>
							</el-form-item>
						</div>
						<div>
							<el-form-item label="持卡类型">
								<el-select placeholder="请选择持卡类型" v-model="ruleForm.card_id" @change="cardtychange">
									<el-option label="无" value=" "></el-option>
									<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
						</div>
						<div style="margin-right: 5em">
							<el-form-item label="充值套餐选择">
								<el-select placeholder="请选择充值套餐" v-model="vip_package_detail"
									@change="vippackageschange">
									<el-option v-for="(item, index) in vip_package" :key="index" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div>
							<el-form-item label="已收金额">
								<el-input placeholder="请输入已收金额" :disabled="true" v-model="ruleForm.money">
									<div slot="suffix">元</div>
								</el-input>
							</el-form-item>
						</div>

						<div class="hei">
							<el-form-item label="收取方式">
								<el-select placeholder="请选择收取方式" v-model="ruleForm.way">
									<el-option v-for="(item, index) in paytype" :key="index" :label="item"
										:value="index"></el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="submitForm('ruleForm')">注册</el-button>
					<!-- <el-button round class="zhuce2">注册并去预约</el-button> -->
				</div>
			</el-tab-pane>
			<el-tab-pane label="使用贵宾室" name="1">
				<div id="yuyue">
					<div class="topbox">
						<el-input value="123" suffix-icon="el-icon-search" class="search" v-model="yuyuesearchcontent"
							placeholder="输入身份证、手机号、卡号">
							<el-select placeholder="请选择持卡类型" slot="prepend" v-model="subscribe.cardtype">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-input>
						<el-button class="searchbtn" @click="yuyuesearchevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<div class="flex">
						<el-form class="zhucehuiyuan" :rules="rules" ref="subscribe" label-position="top"
							label-width="80px" :model="subscribe">
							<el-form-item label="持卡人">
								<el-input placeholder="请输入姓名" v-model="subscribe.name" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="持卡类型">
								<!-- <el-select placeholder="请选择持卡类型" v-model="subscribe.cardtype" disabled="">
									<el-option v-for="(item,index) in cardtype" :key='index' :label="item.name"
										:value="item.id"></el-option>
								</el-select> -->
								<el-input placeholder="请选择持卡类型" v-model="subscribe.card_name" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="卡号">
								<el-input placeholder="请输入卡号" v-model="subscribe.card_no" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="航班号">
								<!-- <el-input placeholder="" v-model="subscribe.flight_no"></el-input>
								<el-select placeholder="" slot="prepend" v-model="" @change="rechargecardtychange">
									<el-option v-for="(item,index) in cardtype" :key='index' :label="item.name"
										:value="item.id"></el-option>
								</el-select> -->
								<el-autocomplete @change="flight_change" @input="flight_change" @blur="flight_change"
									class="inline-input" v-model="subscribe.flight_no" :fetch-suggestions="querySearch"
									placeholder="请输入航班号">
								</el-autocomplete>
							</el-form-item>
							<el-form-item label="进港时间">
								<!-- <el-time-select v-model="subscribe.incoming_time"
									:picker-options="{start: '00:00',step: '00:01',end: '24:00'}" placeholder="选择时间">
								</el-time-select> -->
								<!-- <el-time-picker arrow-control v-model="subscribe.incoming_time"
									:picker-options="{selectableRange: '00:00:00 - 24:00:00'}" format='HH:mm'
									placeholder="选择时间" @change="changetime">
								</el-time-picker> -->
								<el-time-picker v-model="subscribe.incoming_time" value-format="HH:mm"
									placeholder="选择时间">
								</el-time-picker>
							</el-form-item>
							<el-form-item label="划卡次数" v-show="subscribe.card_type==2">
								<el-input placeholder="请输入划卡次数" :min="1" @input="hua_ka_ci_shu"
									v-model="subscribe.along_num">
								</el-input>
							</el-form-item>
							<el-form-item label="预留手机号">
								<el-input placeholder="请输入手机号" v-model="subscribe.phone"></el-input>
							</el-form-item>
						</el-form>
						<el-divider class="shufengexian" direction="vertical"></el-divider>
						<div v-show="subscribe.card_img !== ''&&subscribe.card_img !==undefined" class="imgbox">
							<span v-if="subscribe.is_give==1" class="time2">赠送卡</span>
							<span :class="
							  subscribe.card_name == '纪念卡'
							    ? 'white_txt'
							    : (subscribe.card_name == '黄金卡'
							    ? 'gold_txt'
							    : 'gold_txt') 
							">NO.{{ subscribe.card_no }}</span>
							<img :src="
                  subscribe.card_img 
                " />
							<div class="time">
								<span v-if="subscribe.card_type == 1"
									style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ subscribe.surplus }}</span>
								<span v-else
									style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ subscribe.surplus }}</span>
							</div>
							<div id="" style="font-size: 1.3em;">
								<div class="title" style="margin: 1em 0 0.5em 0;">使用规则：</div>
								<div id="" style="font-size: 0.9em;width: 18.125em;">
									{{subscribe.instructions}}
								</div>
								<div class="title" style="margin: 1em 0 0.5em 0;">使用说明：</div>
								<div id="" style="font-size: 0.9em;width: 18.125em;">
									{{subscribe.consumers_ruler}}
								</div>
							</div>
						</div>

					</div>

				</div>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="yuyue">使用</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="充值" name="2">
				<div id="yuyue">
					<div class="topbox">
						<el-input suffix-icon="el-icon-search" class="search" v-model="rechargesearchcontent"
							placeholder="输入身份证、手机号、卡号">
							<el-select placeholder="请选择持卡类型" slot="prepend" v-model="Recharge.cardtype"
								@change="rechargecardtychange">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-input>
						<el-button class="searchbtn" @click="rechargesearchevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<div class="flex">
						<el-form class="zhucehuiyuan" :rules="rules" ref="Recharge" label-position="top"
							label-width="80px" :model="Recharge">
							<el-form-item label="持卡人">
								<el-input placeholder="请输入姓名" v-model="Recharge.name" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="持卡类型">
								<!-- <el-select placeholder="请选择持卡类型" v-model="Recharge.cardtype" disabled="">
									<el-option v-for="(item,index) in cardtype" :key='index' :label="item.name"
										:value="item.id"></el-option>
								</el-select> -->
								<el-input placeholder="请选择持卡类型" v-model="Recharge.card_name" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="卡号">
								<el-input placeholder="请输入卡号" v-model="Recharge.card_no" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="剩余次数/到期时间">
								<el-input placeholder="请输入时间" v-model="Recharge.surplus" disabled=""></el-input>
							</el-form-item>
							<el-form-item label="充值套餐选择">
								<el-select placeholder="请选择充值套餐" v-model="Recharge.vip_package"
									@change="rechargevippackageschange">
									<el-option label="请选择充值套餐"></el-option>
									<el-option v-for="(item, index) in vip_package" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="应收金额">
								<el-input placeholder="请输入已收金额" v-model="Recharge.money" disabled="">
									<div slot="suffix">元</div>
								</el-input>
							</el-form-item>
							<el-form-item label="收款方式">
								<el-select placeholder="请选择收取方式" v-model="Recharge.way">
									<el-option v-for="(item, index) in paytype" :key="index" :label="item"
										:value="index"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
						<el-divider class="shufengexian" direction="vertical"></el-divider>
						<div v-show="Recharge.card_img !== ''&&Recharge.card_img !==undefined" class="imgbox">
							<span v-if="Recharge.is_give==1" class="time2">赠送卡</span>
							<span :class="
									  Recharge.card_name == '纪念卡'
									    ? 'white_txt'
									    : (Recharge.card_name == '黄金卡'
									    ? 'gold_txt'
									    : 'gold_txt') 
									">NO.{{ Recharge.card_no }}</span>
							<img :src="
						  Recharge.card_img  
						" />
							<div class="time">
								<span v-if="Recharge.card_type == 1"
									style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ Recharge.surplus }}</span>
								<span v-else
									style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ Recharge.surplus }}</span>
							</div>
							<div id="" style="font-size: 1.3em;">
								<div class="title" style="margin: 1em 0 0.5em 0;">使用规则：</div>
								<div id="" style="font-size: 0.9em;width: 18.125em;">
									{{Recharge.instructions}}
								</div>
								<div class="title" style="margin: 1em 0 0.5em 0;">使用说明：</div>
								<div id="" style="font-size: 0.9em;width: 18.125em;">
									{{Recharge.consumers_ruler}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="rechargeevent">充值</el-button>
				</div>
			</el-tab-pane>
			<!-- <el-tab-pane label="补卡" name="3">补卡</el-tab-pane> -->
			<el-tab-pane label="挂失" name="3">
				<div id="yuyue">
					<div class="topbox">
						<el-input suffix-icon="el-icon-search" class="search" v-model="report_the_losssearchcontent"
							placeholder="输入身份证、手机号、卡号">
							<el-select placeholder="请选择持卡类型" slot="prepend" v-model="report_the_loss.cardtype"
								@change="report_the_losscardtychange">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-input>
						<el-button class="searchbtn" @click="report_the_losssearchevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<div class="flex">
						<el-form class="zhucehuiyuan" :rules="rules" ref="ruleForm" label-position="top"
							label-width="80px" :model="report_the_loss">
							<el-form-item label="持卡人">
								<el-input disabled="" placeholder="请输入姓名" v-model="report_the_loss.name"></el-input>
							</el-form-item>
							<el-form-item label="持卡类型">
								<!-- <el-select disabled="" placeholder="请选择持卡类型" v-model="report_the_loss.cardtype">
									<el-option v-for="(item,index) in cardtype" :key='index' :label="item.name"
										:value="item.id"></el-option>
								</el-select> -->
								<el-input disabled="" placeholder="请选择持卡类型" v-model="report_the_loss.card_name">
								</el-input>
							</el-form-item>
							<el-form-item label="卡号">
								<el-input disabled="" placeholder="请输入卡号" v-model="report_the_loss.card_no"></el-input>
							</el-form-item>
							<el-form-item label="剩余次数/到期时间">
								<el-input disabled="" placeholder="请输入时间" v-model="report_the_loss.surplus"></el-input>
							</el-form-item>
						</el-form>
						<el-divider class="shufengexianguashi" direction="vertical"></el-divider>
						<div v-show="report_the_loss.card_img !== ''&&report_the_loss.card_img !==undefined"
							class="imgbox">
							<span v-if="Recharge.is_give==1" class="time2">赠送卡</span>
							<span :class="
									  report_the_loss.card_name == '纪念卡'
									    ? 'white_txt'
									    : (report_the_loss.card_name == '黄金卡'
									    ? 'gold_txt'
									    : 'gold_txt') 
									">NO.{{ report_the_loss.card_no }}</span>
							<img :src="report_the_loss.card_img" @click="tupiandayin" />
							<div class="time">
								<span v-if="report_the_loss.card_type == 1"
									style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ report_the_loss.surplus }}</span>
								<span v-else
									style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ report_the_loss.surplus }}</span>
							</div>
							<div id="" style="font-size: 1.3em;">
								<div class="title" style="margin: 1em 0 0.5em 0;">使用规则：</div>
								<div id="" style="font-size: 0.9em;width: 18.125em;">
									{{report_the_loss.instructions}}
								</div>
								<div class="title" style="margin: 1em 0 0.5em 0;">使用说明：</div>
								<div id="" style="font-size: 0.9em;width: 18.125em;">
									{{report_the_loss.consumers_ruler}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="report_the_lossevent">挂失</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="换卡" name="4">
				<div id="yuyue">
					<div class="topbox">
						<el-input suffix-icon="el-icon-search" class="search" v-model="change_cardsearchcontent"
							placeholder="输入身份证、手机号、卡号">
							<el-select placeholder="请选择持卡类型" slot="prepend" v-model="change_card_select"
								@change="change_cardcardtychange">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-input>
						<el-button class="searchbtn" @click="change_cardcardsearchevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<div class="flex">
						<el-form class="zhucehuiyuan" :rules="rules" ref="change_card" label-position="top"
							label-width="80px" :model="change_card">
							<el-form-item label="持卡人">
								<el-input placeholder="请输入姓名" v-model="change_card.name" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="持卡类型">
								<el-select placeholder="请选择持卡类型" v-model="change_card.card_name" :disabled="true">
									<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="卡号">
								<el-input placeholder="请输入卡号" v-model="change_card.card_no" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="剩余次数/到期时间">
								<el-input placeholder="请输入时间" v-model="change_card.surplus" :disabled="true"></el-input>
							</el-form-item>
							<div id="" style="font-size: 16px; margin: 1.125em 0 1.625em 0">
								更换会员卡为
							</div>
							<el-form-item label="卡号">
								<el-input placeholder="请输入卡号" v-model="change_card.cardno">
								</el-input>
							</el-form-item>
							<el-form-item label="持卡类型">
								<el-select placeholder="请选择持卡类型" v-model="change_card.cardtype"
									@change="change_cardnewcardtychange">
									<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="充值套餐选择">
								<el-select placeholder="请选择充值套餐" v-model="change_card.vip_package"
									@change="change_cardvippackageschange">
									<el-option v-for="(item, index) in vip_package" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="应收金额">
								<el-input placeholder="请输入已收金额" v-model="change_card.money" :disabled="true">
									<div slot="suffix">元</div>
								</el-input>
							</el-form-item>
							<el-form-item label="收款方式">
								<el-select placeholder="请选择收取方式" v-model="change_card.way">
									<el-option v-for="(item, index) in paytype" :key="index" :label="item"
										:value="index"></el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="change_cardevent">换卡</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="绑卡" name="5">
				<div id="bangka">
					<div class="topbox">
						<el-input suffix-icon="el-icon-search" class="search" v-model="binding_cardsearchcontent"
							placeholder="输入身份证、手机号 ">
							<!-- <el-select placeholder="请选择持卡类型" slot="prepend" v-model="binding_card_top_select"
								@change="binding_cardcardtychange">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select> -->
						</el-input>
						<el-button class="searchbtn" @click="binding_cardsearchevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<div class="flex">
						<el-form class="zhucehuiyuan" :rules="rules" ref="binding_card" label-position="top"
							label-width="80px" :model="binding_card">
							<div class="title">
								<div class="roundblue"></div>
								<span style="
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 0.875em;
                  ">基本信息</span>
							</div>
							<el-form-item label="姓名">
								<el-input placeholder="请输入姓名" v-model="binding_card.real_name"></el-input>
							</el-form-item>
							<el-form-item label="性别">
								<el-radio class="sex" v-model="binding_card.gender" label="1" border>男士</el-radio>
								<el-radio class="sex" v-model="binding_card.gender" label="2" border>女士</el-radio>
							</el-form-item>
							<div class="flex">
								<!-- <div class="hei" style="margin-right: 5em;">
									<el-form-item label="密码">
										<el-input placeholder="请输入密码" v-model="binding_card.password"></el-input>
									</el-form-item>
								</div> -->
								<div>
									<el-form-item label="手机号">
										<el-input disabled="" placeholder="请输入手机号" v-model="binding_card.phone">
										</el-input>
									</el-form-item>
								</div>
								<div class="hei">
									<el-form-item label="身份证号">
										<el-input disabled="" placeholder="请输入身份证号" v-model="binding_card.Id_card">
										</el-input>
									</el-form-item>
								</div>
								<!-- <div>
									<el-form-item label="身份标签">
										<el-input disabled="" placeholder="请输入身份标签" v-model="binding_card.Id_tag">
										</el-input>
									</el-form-item>
								</div> -->
							</div>
							<div class="title">
								<div class="roundblue"></div>
								<span style="display: inline-block;vertical-align: middle;font-size: 0.875em;">绑卡</span>
							</div>
							<div class="flex">
								<div class="hei">
									<el-form-item label="卡号">
										<el-input placeholder="请输入卡号" v-model="binding_card.card_no">
										</el-input>
									</el-form-item>
								</div>
								<div class="hei" style="margin-right: 5em">
									<el-form-item label="持卡类型">
										<el-select placeholder="请选择持卡类型" v-model="binding_card.cardtype"
											@change="binding_cardcardtychange">
											<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
												:value="item.id"></el-option>
										</el-select>
									</el-form-item>
								</div>
								<div class="hei" style="margin-right: 5em">
									<el-form-item label="绑卡类型">
										<el-select placeholder="请选择绑卡类型" v-model="binding_card.bind_card"
											@change="binding_cardbind_cardchange">
											<el-option label="普通卡" :value="1"></el-option>
											<el-option label="赠送卡" :value="2"></el-option>
										</el-select>
									</el-form-item>
								</div>
								<div v-show="binding_card.bind_card==1">
									<el-form-item label="充值套餐选择">
										<el-select placeholder="请选择充值套餐" v-model="binding_card.vip_package"
											@change="binding_cardvippackageschange">
											<el-option v-for="(item, index) in vip_package" :key="index"
												:label="item.name" :value="item.id"></el-option>
										</el-select>
									</el-form-item>
								</div>
								<div class="hei" v-show="binding_card.bind_card==1">
									<el-form-item label="已收金额">
										<el-input placeholder="请输入已收金额" v-model="binding_card.money">
											<div slot="suffix">元</div>
										</el-input>
									</el-form-item>
								</div>
								<div v-show="binding_card.bind_card==1">
									<el-form-item label="收取方式">
										<el-select placeholder="请选择收取方式" v-model="binding_card.way">
											<el-option v-for="(item, index) in paytype" :key="index" :label="item"
												:value="index"></el-option>
										</el-select>
									</el-form-item>
								</div>
							</div>
						</el-form>
						<el-divider class="shufengexian" direction="vertical"></el-divider>
						<div v-show="binding_card.card_name" class="imgbox">
							<img :src="
                  binding_card.card_name == '纪念卡'
                    ? require('../assets/2.png')
                    : binding_card.card_name == '黄金卡'
                    ? require('../assets/1.png')
                    : require('../assets/3.png')
                " />
							<div class="time">
								<span v-if="binding_card.card_type == 1"
									style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ binding_card.surplus }}</span>
								<span v-else
									style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ binding_card.surplus }}</span>
							</div>
						</div>
					</div>
				</div>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="binding_cardevent">绑卡</el-button>
				</div>
			</el-tab-pane>
			<!-- <el-tab-pane label="赠送" name="6">
				<div id="zengsong">
					<div class="topbox">
						<el-input suffix-icon="el-icon-search" class="search" v-model="givesearchcontent"
							placeholder="输入身份证、手机号、卡号">
							<el-select placeholder="请选择持卡类型" slot="prepend" v-model="give.cardtype"
								@change="givecardtychange">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-input>
						<el-button class="searchbtn" @click="givesearchevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<div class="flex">
						<el-form class="zhucehuiyuan" :rules="rules" ref="give" label-position="top" label-width="80px"
							:model="give">
							<el-form-item label="持卡人">
								<el-input disabled="" placeholder="请输入持卡人姓名" v-model="give.name"></el-input>
							</el-form-item>
							<el-form-item label="持卡类型">
								<el-select disabled="" placeholder="请选择持卡类型" v-model="give.cardtype"
									@change="givecardtychange">
									<el-option v-for="(item,index) in cardtype" :key='index' :label="item.name"
										:value="item.id"></el-option>
								</el-select>
								<el-input disabled="" placeholder="请选择持卡类型" v-model="give.card_name"></el-input>
							</el-form-item>
							<el-form-item label="卡号">
								<el-input disabled="" placeholder="请输入卡号" v-model="give.card_no"></el-input>
							</el-form-item>
							<el-form-item label="剩余次数/到期时间">
								<el-input disabled="" placeholder="请输入剩余次数/到期时间" v-model="give.surplus"></el-input>
							</el-form-item>
							<el-form-item class="special">
								<div id="god" slot="label">
									<div id="" style="font-size: 14px">选择赠送天数</div>
									<div id="" style="font-size: 14px; color: #a0a2ab">
										（剩余可赠送天数/次数：{{ give.can_give_num }}）
									</div>
								</div>
								<el-input placeholder="请输入数量" v-model="give.daynum"></el-input>
							</el-form-item>
						</el-form>
						<el-divider class="shufengexianguashi" direction="vertical"></el-divider>
						<div v-show="give.card_name !== ''" class="imgbox">
							<img :src="
                  give.card_name == '纪念卡'
                    ? require('../assets/2.png')
                    : give.card_name == '黄金卡'
                    ? require('../assets/1.png')
                    : require('../assets/3.png')
                " />
							<div class="time">
								<span v-if="give.card_type == 1"
									style="font-size: 0.875em; line-height: 0.785714em">到期时间：{{ give.surplus }}</span>
								<span v-else
									style="font-size: 0.875em; line-height: 0.785714em">剩余次数：{{ give.surplus }}</span>
							</div>
						</div>
					</div>
				</div>
				<el-divider></el-divider>
				<div class="btnbox">
					<el-button round class="zhuce" @click="giveevent">赠送</el-button>
				</div>
			</el-tab-pane> -->
			<el-tab-pane label="赠送卡" name="7">
				<div id="yuyue">
					<div class="topbox">
						<el-input suffix-icon="el-icon-search" class="search" v-model="give_cardcontent"
							placeholder="输入卡号">
							<el-select placeholder="请选择持卡类型" slot="prepend" v-model="give_card.cardtype">
								<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-input>
						<el-button class="searchbtn" @click="give_cardevent">搜索</el-button>
					</div>
					<el-divider class="fengexian"></el-divider>
					<el-form class="zhucehuiyuan" ref="give_card" label-position="top" label-width="80px"
						:model="give_card">
						<div class="title">
							<div class="roundblue"></div>
							<span style="
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 0.875em;
                ">基本信息</span>
						</div>
						<el-form-item label="姓名">
							<el-input placeholder="请输入姓名" v-model="give_card.real_name"></el-input>
						</el-form-item>
						<el-form-item label="性别">
							<el-radio class="sex" v-model="give_card.gender" label="1" border>男士</el-radio>
							<el-radio class="sex" v-model="give_card.gender" label="2" border>女士</el-radio>
						</el-form-item>
						<div class="flex">
							<!-- <div class="hei" style="margin-right: 5em">
								<el-form-item label="密码">
									<el-input placeholder="请输入密码 ( 不填写默认为身份证后6位 )" v-model="give_card.password">
									</el-input>
								</el-form-item>
							</div> -->
							<div>
								<el-form-item label="手机号">
									<el-input placeholder="请输入手机号" v-model="give_card.phone"></el-input>
								</el-form-item>
							</div>
							<div class="hei">
								<el-form-item label="身份证号">
									<el-input placeholder="请输入身份证号" v-model="give_card.Id_card"></el-input>
								</el-form-item>
							</div>
							<!-- <div>
								<el-form-item label="身份标签">
									<el-input placeholder="请输入身份标签" v-model="give_card.Id_tag"></el-input>
								</el-form-item>
							</div> -->
						</div>
						<div class="title">
							<div class="roundblue"></div>
							<span style="
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 0.875em;
                ">选填信息</span>
						</div>
						<div class="flex">
							<div class="hei" style="margin-right: 5em">
								<el-form-item label="工作单位">
									<el-input placeholder="请输入工作单位" v-model="give_card.work_units"></el-input>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="爱好">
									<el-input placeholder="请输入爱好" v-model="give_card.hobby"></el-input>
								</el-form-item>
							</div>
							<div class="hei">
								<el-form-item label="车牌号">
									<el-input placeholder="请输入车牌号" v-model="give_card.plateNO"></el-input>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="饮食爱好">
									<el-input placeholder="请输入饮食爱好" v-model="give_card.food_preference"></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="title">
							<div class="roundblue"></div>
							<span style="
                  display: inline-block;
                  vertical-align: middle;
                  font-size: 0.875em;
                ">赠送卡信息</span>
						</div>
						<div class="flex">
							<div class="hei" style="margin-right: 5em">
								<el-form-item label="持卡类型">
									<el-select disabled="" placeholder="请选择持卡类型" v-model="give_card.card_id"
										@change="cardtychange">
										<el-option v-for="(item, index) in cardtype" :key="index" :label="item.name"
											:value="item.id"></el-option>
									</el-select>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="剩余次数/到期时间">
									<el-input disabled="" placeholder="请输入时间" v-model="give_card.surplus" disabled="">
									</el-input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<el-divider></el-divider>
				</div>
				<div class="btnbox">
					<el-button round class="zhuce" @click="give_card_event">赠送</el-button>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				give_card_dialog: false,
				bind_card_many_people: false,
				change_card_many_people: false,
				report_the_loss_many_people: false,
				recharge_many_people: false,
				// 一人多卡：预约
				subscribe_many_people: false,
				change_card_select: "",
				give_cardcontent: "",
				register_vip_dialog: false,
				binding_card_number: "",
				binding_card_dialog: false,
				change_card_number: "",
				changecard_dialog: false,
				input: "",
				activeName: "0",
				ruleForm: {
					real_name: "",
					phone: "",
					password: "",
					gender: "1",
					Id_card: "",
					Id_tag: "",
					work_units: "",
					hobby: "",
					plateNO: "",
					food_preference: "",
					card_id: "",
					sku_id: "",
					money: "",
					way: "",
					card_type: "",
					card_no: "",
				},
				give_card: {
					real_name: "",
					phone: "",
					password: "",
					gender: "1",
					Id_card: "",
					Id_tag: "",
					work_units: "",
					hobby: "",
					plateNO: "",
					food_preference: "",
					card_id: "",
					sku_id: "",
					money: "",
					way: "",
					card_type: "",
					card_no: "",
				},
				subscribe: {
					name: "",
					user_id: "",
					flight_no: "",
					card_no: "",
					incoming_time: "",
					along_num: "",
					reserved_phone: "",
					card_name: "",
					card_type: "",
					surplus: "",
				},
				Recharge: {
					user_id: "", //会员id
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
				},
				report_the_loss: {
					user_id: "", //会员id
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
				},
				change_card: {
					user_id: "", //会员id
					card_id: "", //要换卡id
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
					oldcardtype: "",
				},
				binding_card: {
					user_id: "", //会员id
					card_id: "", //持卡类型id
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
					gender: "1",
					Id_card: "",
					Id_tag: "",
					phone: "",
					bind_card: 1
				},
				binding_card2: {
					user_id: "", //会员id
					card_id: "", //持卡类型id
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					card_no: "",
				},
				give: {
					user_id: "", //会员id
					daynum: "", //赠送天数/次数
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
					can_give_num: "",
				},
				restaurants: [],
				rules: {
					name: [{
							required: true,
							message: "请输入名称",
							trigger: "blur",
						},
						{
							min: 3,
							max: 5,
							message: "长度在 3 到 5 个字符",
							trigger: "blur",
						},
					],
					region: [{
						required: true,
						message: "请选择活动区域",
						trigger: "change",
					}, ],
					date1: [{
						type: "date",
						required: true,
						message: "请选择日期",
						trigger: "change",
					}, ],
					date2: [{
						type: "date",
						required: true,
						message: "请选择时间",
						trigger: "change",
					}, ],
					type: [{
						type: "array",
						required: true,
						message: "请至少选择一个活动性质",
						trigger: "change",
					}, ],
					resource: [{
						required: true,
						message: "请选择活动资源",
						trigger: "change",
					}, ],
					desc: [{
						required: true,
						message: "请填写活动形式",
						trigger: "blur",
					}, ],
					value: [{
						required: true,
						message: "请填写活动形式",
						trigger: "blur",
					}, ],
				},
				cardtype: [],
				binding_card_img: '',
				paytype: [],
				give_card_number: "",
				vip_package: [],
				vip_package_detail: "",
				yuyuesearch: "",
				yuyuesearchcontent: "",
				rechargesearchcontent: "",
				report_the_losssearchcontent: "",
				change_cardsearchcontent: "",
				binding_cardsearchcontent: "",
				givesearchcontent: "",
				ruleForm_number: "",
				binding_card_top_select: "",
				search_lists: [],
				change_card_img: "",
				give_card_img: "",
				ruleForm_img: ""
			};
		},
		methods: {
			hua_ka_ci_shu() {
				console.log(this.subscribe.along_num)
				if ((this.subscribe.along_num - 0) <= 0) {
					this.subscribe.along_num = ""
				}
			},
			close_subscribe_many_people() {
				console.log("")
			},
			// 预约多人选择卡片事件
			subscribe_many_people_choose(e) {
				console.log("选择的数据", this.subscribe[e])
				this.subscribe = this.subscribe[e]
				this.subscribe_many_people = false
			},
			// 充值多人选择卡片事件
			recharge_many_people_choose(e) {
				this.Recharge = this.Recharge[e]
				this.rechargecardtychange(this.Recharge.card_id);
				this.recharge_many_people = false
				console.log("选择的数据", this.Recharge)
			},
			// 挂失多人选择卡片事件
			report_the_loss_many_people_choose(e) {
				console.log("选择的数据", this.report_the_loss[e])
				this.report_the_loss = this.report_the_loss[e]
				this.report_the_loss_many_people = false
			},
			// 换卡多人选择卡片事件
			change_card_many_people_choose(e) {
				console.log("选择的数据", this.change_card[e])
				this.change_card = this.change_card[e]
				this.$set(this.change_card, 'cardno', '')
				this.$set(this.change_card, 'sku_id', '')
				this.change_card_many_people = false
			},

			// 模糊查询并且判断航班时间重新赋值
			flight_change(e) {
				console.log(e);
				this.restaurants.forEach((v, i) => {
					if (e == v.flight_no) {
						console.log(v, i);
						this.subscribe.incoming_time = v.in_time;
					}
				});
			},
			querySearch(queryString, cb) {
				var restaurants = this.search_lists;
				var results = queryString ?
					restaurants.filter(this.createFilter(queryString)) :
					restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
					return (
						restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
						0
					);
				};
			},
			changetime(e) {
				console.log(e);
				this.subscribe.incoming_time = e;
			},
			// 赠送卡事件
			give_card_event() {
				const that = this;
				const data = that.give_card;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("bind/give/card", data, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});

							that.give_card_dialog = true;
							that.give_card_number = res.data.data.cardno;
							that.give_card_img = res.data.data.card_img;
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 赠送
			giveevent() {
				const that = this;
				const data = that.give;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("givedaynum", data, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});

							// that.binding_cardsearchcontent = "";

							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 关闭赠送卡弹出层
			close_give_card_dialog() {
				this.give_card = {
					real_name: "",
					phone: "",
					password: "",
					gender: "1",
					Id_card: "",
					Id_tag: "",
					work_units: "",
					hobby: "",
					plateNO: "",
					food_preference: "",
					card_id: "",
					sku_id: "",
					money: "",
					way: "",
					card_type: "",
					card_no: "",
				};
				this.givesearchcontent = "";
				this.give_card_dialog = false
				console.log(this.change_card);
			},
			// 绑卡
			binding_cardevent() {
				const that = this;
				const data = that.binding_card;
				const data2 = that.binding_card2
				data2.user_id = data.user_id
				data2.card_id = data.card_id
				data2.sku_id = data.sku_id
				data2.money = data.money
				data2.way = data.way
				data2.card_no = data.card_no
				data2.bind_card = data.bind_card
				// if(data2.bind_card==2){
				// 	data2.
				// }
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("bindcard", data2, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});
							that.binding_cardsearchcontent = "";
							that.binding_card_dialog = true;
							that.binding_card_number = res.data.data.cardno;
							that.binding_card_img = res.data.data.card_img
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 关闭绑卡弹出层
			close_binding_card_dialog() {
				this.binding_card_dialog = false;
				this.binding_card = {
					user_id: "", //会员id
					card_id: "", //持卡类型id
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
					gender: "1",
					Id_card: "",
					Id_tag: "",
				};
				console.log(this.change_card);
			},
			// 关闭换卡弹出层
			close_change_dialog() {
				this.changecard_dialog = false;
				this.change_card = {
					user_id: "", //会员id
					card_id: "", //要换卡id
					sku_id: "", //套餐id
					money: "", //所选套餐费用
					way: "", //收款方式
					name: "",
					card_no: "",
					surplus: "",
					card_name: "",
					card_type: "",
					oldcardtype: "",
				};
				console.log(this.change_card);
			},
			// 换卡
			change_cardevent() {
				const that = this;
				const data = that.change_card;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("changecard", data, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});
							that.changecard_dialog = true;
							if (that.changecard_dialog == false) {
								console.log("换卡类型：", that.change_card.cardtype);
							}
							that.change_cardsearchcontent = "";
							that.change_card_number = res.data.data.cardno;
							that.change_card_img = res.data.data.card_img
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 挂失
			report_the_lossevent() {
				const that = this;
				const data = that.report_the_loss;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("lossreprot", data, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});
							that.report_the_loss = {
								user_id: "", //会员id
								name: "",
								card_no: "",
								surplus: "",
								card_name: "",
								card_type: "",
							};
							that.report_the_losssearchcontent = "";
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 充值
			rechargeevent() {
				const that = this;
				const data = that.Recharge;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("rechargecard", data, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});
							that.Recharge = {
								user_id: "", //会员id
								sku_id: "", //套餐id
								money: "", //所选套餐费用
								way: "", //收款方式
								name: "",
								card_no: "",
								surplus: "",
								card_name: "",
								card_type: "",
							};
							that.rechargesearchcontent = "";
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 预约
			yuyue() {
				const that = this;
				const data = that.subscribe;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log(this.ruleForm);
				that.$axios
					.post("appointmentviproom", data, {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});
							that.subscribe = {
								name: "",
								user_id: "",
								flight_no: "",
								card_no: "",
								incoming_time: "",
								along_num: "",
								reserved_phone: "",
								card_name: "",
								card_type: "",
								surplus: "",
							};
							that.yuyuesearchcontent = "";
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 赠卡
			give_cardevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("searchgivecard", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.give_card.cardtype,
							card_no: that.give_cardcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "success",
							});
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.give_card.card_no = res.data.data.cno;
							that.give_card_number = res.data.data.cno;
							that.give_card.card_id = res.data.data.card_id;
							that.give_card.surplus = res.data.data.daynum;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 赠送顶部搜索事件
			givesearchevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("search", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.give.cardtype,
							keyword: that.givesearchcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.give.user_id = res.data.data.user_id;
							that.give.card_no = res.data.data.card_no;
							that.give.name = res.data.data.name;
							that.give.card_name = res.data.data.card_name;
							that.give.card_type = res.data.data.card_type;
							that.give.surplus = res.data.data.surplus;
							that.give.can_give_num = res.data.data.can_give_num;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 换卡顶部搜索事件
			change_cardcardsearchevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("search", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.change_card_select,
							keyword: that.change_cardsearchcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.change_card = res.data.data
							that.change_card_many_people = true
							// that.change_card.user_id = res.data.data.user_id;
							// that.change_card.card_no = res.data.data.card_no;
							// that.change_card.card_id = res.data.data.card_id;
							// that.change_card.name = res.data.data.name;
							// that.change_card.card_name = res.data.data.card_name;
							// that.change_card.oldcardtype = res.data.data.card_id;
							// that.change_card.surplus = res.data.data.surplus;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 绑卡顶部搜索事件
			binding_cardsearchevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("bindsearch", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.binding_card_top_select,
							keyword: that.binding_cardsearchcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							// that.binding_card = res.data.data
							// that.bind_card_many_people = true
							that.binding_card.user_id = res.data.data.user_id;
							that.binding_card.card_no = res.data.data.card_no;
							that.binding_card.real_name = res.data.data.real_name;
							that.binding_card.card_name = res.data.data.card_name;
							that.binding_card.card_type = res.data.data.card_type;
							that.binding_card.surplus = res.data.data.surplus;
							that.binding_card.Id_card = res.data.data.Id_card;
							that.binding_card.Id_tag = res.data.data.Id_tag;
							that.binding_card.phone = res.data.data.phone;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 挂失顶部搜索事件
			report_the_losssearchevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("search", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.report_the_loss.cardtype,
							keyword: that.report_the_losssearchcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.report_the_loss = res.data.data
							that.report_the_loss_many_people = true
							// that.report_the_loss.user_id = res.data.data.user_id;
							// that.report_the_loss.card_no = res.data.data.card_no;
							// that.report_the_loss.name = res.data.data.name;
							// that.report_the_loss.card_name = res.data.data.card_name;
							// that.report_the_loss.card_type = res.data.data.card_type;
							// that.report_the_loss.surplus = res.data.data.surplus;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 充值顶部搜索事件
			rechargesearchevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("search", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.Recharge.cardtype,
							keyword: that.rechargesearchcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.Recharge = res.data.data
							that.recharge_many_people = true
							// that.Recharge.user_id = res.data.data.user_id;
							// that.Recharge.card_no = res.data.data.card_no;
							// that.Recharge.name = res.data.data.name;
							// that.Recharge.card_name = res.data.data.card_name;
							// that.Recharge.card_type = res.data.data.card_type;
							// that.Recharge.surplus = res.data.data.surplus;
							// that.Recharge.card_id = res.data.data.card_id;

						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 预约顶部搜索事件
			yuyuesearchevent() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("search", {
						headers: {
							token: token,
						},
						params: {
							card_id: that.subscribe.cardtype,
							keyword: that.yuyuesearchcontent,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.subscribe = res.data.data
							that.subscribe_many_people = true
							// that.subscribe.user_id = res.data.data.user_id;
							// that.subscribe.card_no = res.data.data.card_no;
							// that.subscribe.name = res.data.data.name;
							// that.subscribe.card_name = res.data.data.card_name;
							// that.subscribe.card_type = res.data.data.card_type;
							// that.subscribe.surplus = res.data.data.surplus;
							// that.subscribe.reserved_phone = res.data.data.phone;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: "error",
							});
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 注册会员界面功能
			cardtychange(e) {
				console.log(e);
				this.vip_package_detail = "";
				this.ruleForm.money = "";
				this.getvipcontent(e);
				console.log(this.cardtype);
				this.ruleForm.card_type = e;
			},
			vippackageschange(e) {
				console.log(e);
				this.ruleForm.sku_id = e;
				this.vip_package.forEach((v, i) => {
					console.log(v, i);
					if (e == v.id) {
						this.ruleForm.money = v.money;
					}
				});
			},
			// 赠送界面功能
			givecardtychange(e) {
				console.log(e);
				this.give.card_id = e;
				this.vip_package_detail = "";
				this.give.money = "";
				this.getvipcontent(e);
			},
			givevippackageschange(e) {
				console.log(e);
				this.give.sku_id = e;
				this.vip_package.forEach((v, i) => {
					console.log(v, i);
					if (e == v.id) {
						this.give.money = v.money;
					}
				});
			},
			// 绑卡界面功能
			binding_cardcardtychange(e) {
				console.log(e);
				this.binding_card.card_id = e;
				this.vip_package_detail = "";
				this.binding_card.money = "";
				this.getvipcontent(e);
			},
			binding_cardbind_cardchange() {},
			binding_cardvippackageschange(e) {
				console.log(e);
				this.binding_card.sku_id = e;
				this.vip_package.forEach((v, i) => {
					console.log(v, i);
					if (e == v.id) {
						this.binding_card.money = v.money;
					}
				});
			},
			// 换卡界面功能
			change_cardnewcardtychange(e) {
				console.log(e);
				this.change_card.card_id = e;
				this.vip_package_detail = "";
				this.change_card.vip_package = "";
				this.change_card.money = "";
				this.getvipcontent(e);
			},
			change_cardcardtychange(e) {
				console.log(e);
				this.vip_package_detail = "";
				this.change_card.vip_package = "";
				this.change_card.money = "";
				this.getvipcontent(e);
			},
			change_cardvippackageschange(e) {
				console.log(e);
				this.change_card.sku_id = e;
				this.vip_package.forEach((v, i) => {
					console.log(v, i);
					if (e == v.id) {
						this.change_card.money = v.money;
					}
				});
			},
			// 挂失界面功能
			report_the_losscardtychange(e) {
				console.log(e);
				this.vip_package_detail = "";
				this.report_the_loss.money = "";
				this.getvipcontent(e);
			},
			report_the_lossvippackageschange(e) {
				console.log(e);
				this.report_the_loss.sku_id = e;
				this.vip_package.forEach((v, i) => {
					console.log(v, i);
					if (e == v.id) {
						this.report_the_loss.money = v.money;
					}
				});
			},
			tupiandayin() {
				console.log(this.report_the_loss.card_img)
			},
			// 充值界面功能
			rechargecardtychange(e) {
				console.log(e);
				this.vip_package_detail = "";
				this.Recharge.money = "";
				this.getvipcontent(e);
			},
			rechargevippackageschange(e) {
				console.log(e);
				this.Recharge.sku_id = e;
				this.vip_package.forEach((v, i) => {
					console.log(v, i);
					if (e == v.id) {
						this.Recharge.money = v.money;
					}
				});
			},
			// 航班查询
			get_flight(e) {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("searchflight", {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							this.restaurants = res.data.data;
							this.search_lists = [];
							for (let item of this.restaurants) {
								this.search_lists.push({
									value: item.flight_no,
								});
							}
							console.log("航班查询", this.restaurants);
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 会员卡套餐
			getvipcontent(e) {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("packages", {
						headers: {
							token: token,
						},
						params: {
							card_id: e,
						},
					})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.vip_package = res.data.data.packages;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 持卡类型
			getcardtype() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("cardtype", {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						// console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.cardtype = res.data.data;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			// 收款方式
			getpaytype() {
				const that = this;
				var token = JSON.parse(sessionStorage.getItem("token"));
				console.log("保存的token：", token);
				that.$axios
					.get("payway", {
						headers: {
							token: token,
						},
					})
					.then((res) => {
						// console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.paytype = res.data.data;
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + "需要重新登陆",
								type: "error",
							});
							sessionStorage.removeItem("token");
							that.$router.push("/");
						}
					})
					.catch((error) => {
						console.log(error); //  错误处理 相当于error
					});
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// 关闭绑卡弹出层
			close_binding_register_vip_dialog() {
				this.register_vip_dialog = false;
				this.ruleForm = {
					real_name: "",
					phone: "",
					password: "",
					gender: "1",
					Id_card: "",
					Id_tag: "",
					work_units: "",
					hobby: "",
					plateNO: "",
					food_preference: "",
					card_id: "",
					sku_id: "",
					money: "",
					way: "",
				};
				console.log(this.change_card);
			},
			// 注册
			submitForm(formName) {
				const that = this;
				const data = that.ruleForm;
				var token = JSON.parse(sessionStorage.getItem("token"));
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.ruleForm);
						that.$axios
							.post(
								"member/register", {
									data,
								}, {
									headers: {
										token: token,
									},
								}
							)
							.then((res) => {
								console.log(res);
								//  处理成功的函数 相当于success
								if (res.data.code == 200) {
									that.ruleForm_number = res.data.data.card_no;
									that.ruleForm_img = res.data.data.card_img
									that.register_vip_dialog = true;
									that.$message({
										showClose: true,
										message: res.data.msg,
										type: "success",
									});

									that.vip_package_detail = "";
									// var token = JSON.parse(sessionStorage.getItem('token'))
									// console.log("获取的token：", token)
								} else if (res.data.code == -1) {
									that.$message({
										showClose: true,
										message: res.data.msg + "需要重新登陆",
										type: "error",
									});
									sessionStorage.removeItem("token");
									that.$router.push("/");
								} else if (res.data.code == 0) {
									that.$message({
										showClose: true,
										message: res.data.msg,
										type: "error",
									});
								}
							})
							.catch((error) => {
								console.log(error); //  错误处理 相当于error
							});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		},
		mounted() {
			this.getcardtype();
			this.getpaytype();
			this.get_flight();
			console.log(this.$route.params);
			if (
				this.$route.params.index == "" ||
				this.$route.params.index == null ||
				this.$route.params.index == undefined
			) {
				this.activeName = "0";
			} else {
				this.activeName = this.$route.params.index;
			}
		},
	};
</script>

<style lang="less">
	#yewubanli {
		background-color: #fff;
		border: 1px solid #eeeeee;
		margin: 1.875em 2.5em 0 2.5em;
		border-radius: 1.25em;

		.el-input.is-disabled .el-input__inner {
			color: black;
		}

		.el-dialog {
			border-radius: 0.1875em;

			.title {
				font-size: 14px;
				text-align: center;
				margin: 2.85714285em 0 1.4285714285em 0;
			}

			.el-dialog__body {
				padding: unset;

				.white_txt {
					font-size: 1.5625em;
					color: white;
					position: absolute;
					left: 5.7em;
					top: 9.8em;
				}

				.gold_txt {
					font-size: 1.5625em;
					color: #ffdd6a;
					position: absolute;
					left: 5.7em;
					top: 9.8em;
				}

				.time {
					box-sizing: border-box;
					position: absolute;
					width: 12.625em;
					height: 3.625em;
					background: #fc5346;
					border-radius: 0.625em;
					padding: 1.375em 0.875em;
					color: #ffffff;
					top: 13.8125em;
					left: 27.25em;
					line-height: 100%;
				}

				.time2 {
					box-sizing: border-box;
					position: absolute;
					width: 12.625em;
					height: 3.625em;
					background: #fc5346;
					border-radius: 0.625em;
					padding: 1.375em 0.875em;
					color: #ffffff;
					top: 9.8125em;
					left: 27.25em;
					line-height: 100%;
				}
			}

			.el-dialog__title {
				font-size: 16px;
			}

			.el-dialog__headerbtn .el-dialog__close {
				color: #333333;
			}

			.el-dialog__header {
				border-bottom: 1px solid #eeeeee;
			}

			.el-dialog__footer {
				text-align: center;
				padding-top: unset;
				padding-bottom: 1.875em;

				.dialog-btn {
					width: 7.714285em;
					height: 2.5714285em;
					background: #109aff;
					border-radius: 1.285714285em;
					line-height: 0;

					span {
						color: white;
						font-size: 14px;
					}
				}
			}
		}

		.toptabs {
			.topbox {
				padding: 0 0 2.142857em 0;

				.searchbtn {
					width: 5.714285em;
					height: 2.85714285em;
					background: #ffffff;
					border: 1px solid #1890ff;
					border-radius: 1.428571em;
					margin-left: 1.142857em;
					padding: 0.92857em 1.8571428em;

					span {
						font-size: 14px;
						color: #1890ff;
					}
				}

				.search {
					font-size: 14px;
					width: 25.0714285em;
					height: 2.85714285em;
					margin-top: 0.714em;

					.el-input__inner {
						width: 25.0714285em;
						height: 2.85714285em;
						background-color: #f2f4ff;
						border: none;
						color: black;
					}

					.el-input__suffix {
						margin-right: 1.25em;
					}

					.el-select__caret {
						line-height: 2.49999em !important;
					}

					.el-input-group__prepend {
						border: none;

						.el-input__inner {
							width: 12.071428em !important;
							border-radius: 0.35714em !important;
						}
					}
				}
			}

			.el-tabs__active-bar {}

			.el-select__caret {
				line-height: 2.49999em !important;
			}

			.el-tabs__header {
				margin-bottom: 1.875em;
			}

			.roundblue {
				display: inline-block;
				vertical-align: middle;
				width: 0.625em;
				height: 0.625em;
				background: #ffffff;
				border: 0.125em solid #1890ff;
				border-radius: 50%;
				margin-right: 0.375em;
			}

			.el-tabs__item {
				font-size: 16px;
				height: 3.625em;
				line-height: 3.75em;
			}

			.el-tabs__content {
				height: 100%;
			}

			.el-tabs__nav {
				margin-left: 3.75em;
			}

			.btnbox {
				text-align: center;
				padding: 1.6875em 0;

				span {
					font-size: 14px;
				}

				.zhuce {
					font-size: 14px !important;
					width: 7.7142em;
					height: 2.5714em;
					background: #109aff;
					border-radius: 1.2857em;
					line-height: 0;
					color: #fff;
				}

				.zhuce2 {
					font-size: 14px !important;
					width: 11.714285em;
					height: 2.5714em;
					background: #f8f8f8;
					border: 1px solid #eeeeee;
					opacity: 1;
					line-height: 0;
					border-radius: 1.285714em;
				}

				.el-button+.el-button {
					margin-left: 2.14285em;
				}
			}

			// 注册
			.zhucehuiyuan {
				width: 57.5em;
				padding-bottom: 5em;
				padding-left: 3.75em;

				.title {
					margin-bottom: 1.375em;
				}

				.sex {
					width: 5.75em;
					border-radius: unset !important;
					margin-right: 0.8125em;
				}

				/* border-bottom: 1px solid #EEEEEE; */
				.el-input {
					width: 29.9999em;
					height: 2.4285em;
				}

				.el-date-editor {
					.el-input__inner {
						height: 2.4285em;
						background-color: #f2f4ff;
						line-height: 2.4285em;
						padding-left: 2.714285em;
					}

					.el-input__icon {
						line-height: 1 !important;
					}
				}

				.el-input__inner {
					height: 2.4285em;
					background-color: #f2f4ff;
					line-height: 2.4285em;
					padding: 0 0.714285em;
				}

				.el-input__suffix {
					margin-right: 1.25em;
				}

				.el-form-item__content {
					line-height: 2.42857em;
				}

				.el-radio.is-bordered+.el-radio.is-bordered {
					margin-left: unset;
				}

				.flex {
					display: flex;
					flex-wrap: wrap;

					.hei {
						margin-right: 5em;
					}
				}
			}

			// 预约
			#yuyue {
				font-size: 0.875em;
				padding-left: 4.28571em;

				.flex {
					display: flex;
					margin-top: 2.5em;
					align-items: stretch;

					.hei {
						margin-right: 5em;
					}

					.shufengexian {
						margin: unset;
						margin-right: 8.125em;
						height: 36.0625em;
						background-color: #eeeeee;
					}

					.shufengexianguashi {
						margin: unset;
						margin-right: 8.125em;
						height: 25.375em;
						background-color: #eeeeee;
					}

					.imgbox {
						position: relative;

						img {
							width: 24.125em;
							height: 15.3125em;
						}

						.white_txt {
							font-size: 1.5625em;
							color: white;
							position: absolute;
							left: 1.1em;
							top: 6.8em;
						}

						.gold_txt {
							font-size: 1.5625em;
							color: #ffdd6a;
							position: absolute;
							left: 1.1em;
							top: 6.8em;
						}

						.time {
							box-sizing: border-box;
							position: absolute;
							width: 12.625em;
							height: 3.625em;
							background: #fc5346;
							border-radius: 0.625em;
							padding: 1.375em 0.875em;
							color: #ffffff;
							top: 9.8125em;
							left: 16.25em;
							line-height: 100%;
						}

						.time2 {
							box-sizing: border-box;
							position: absolute;
							width: 12.625em;
							height: 3.625em;
							background: #fc5346;
							border-radius: 0.625em;
							padding: 1.375em 0.875em;
							color: #ffffff;
							top: 5.8125em;
							left: 16.25em;
							line-height: 100%;
						}
					}
				}

				.zhucehuiyuan {
					width: 42.5em;
					padding-left: unset;

					.el-form-item__label {
						line-height: 1em;
						margin-bottom: 1em;
					}
				}

				.fengexian {
					margin: unset !important;
					width: 80em;
					background-color: #eeeeee;
				}
			}

			#bangka {
				font-size: 0.875em;
				padding-left: 4.28571em;

				.zhucehuiyuan {
					padding-left: 0;
				}

				.flex {
					display: flex;
				}

				.el-form-item__label {
					line-height: 1em;
					margin-bottom: 1em;
				}

				.shufengexian {
					margin: 0 8.125em 2.5em;
					height: 43.375em;
					background-color: #eeeeee;
				}

				.imgbox {
					position: relative;

					img {
						width: 24.125em;
						height: 15.3125em;
					}

					.white_txt {
						font-size: 1.5625em;
						color: white;
						position: absolute;
						left: 1.1em;
						top: 6.8em;
					}

					.gold_txt {
						font-size: 1.5625em;
						color: #ffdd6a;
						position: absolute;
						left: 1.1em;
						top: 6.8em;
					}

					.time {
						box-sizing: border-box;
						position: absolute;
						width: 13.625em;
						height: 3.625em;
						background: #fc5346;
						border-radius: 0.625em;
						padding: 1.375em 1.875em;
						color: #ffffff;
						top: 9.8125em;
						left: 16.25em;
						line-height: 100%;
					}

					.time2 {
						box-sizing: border-box;
						position: absolute;
						width: 12.625em;
						height: 3.625em;
						background: #fc5346;
						border-radius: 0.625em;
						padding: 1.375em 0.875em;
						color: #ffffff;
						top: 5.8125em;
						left: 16.25em;
						line-height: 100%;
					}
				}
			}

			#zengsong {
				font-size: 0.875em;
				padding-left: 4.28571em;

				.zhucehuiyuan {
					width: 42.5em;
					padding-left: unset !important;

					.el-form-item__label {
						line-height: 1em;
						margin-bottom: 1em;
					}
				}

				.flex {
					display: flex;
					margin-top: 2.5em;

					.special {
						.el-form-item__label {
							width: 29.999em;

							#god {
								display: flex;
								justify-content: space-between;
							}
						}
					}
				}

				.el-form-item__label {
					line-height: 1em;
					margin-bottom: 1em;
				}

				.fengexian {
					margin: unset !important;
					width: 80em;
					background-color: #eeeeee;
				}

				.shufengexianguashi {
					margin: unset;
					margin-right: 8.125em;
					height: 25.375em;
					background-color: #eeeeee;
				}

				.imgbox {
					position: relative;

					img {
						width: 24.125em;
						height: 15.3125em;
					}

					.white_txt {
						font-size: 1.5625em;
						color: white;
						position: absolute;
						left: 1.1em;
						top: 6.8em;
					}

					.gold_txt {
						font-size: 1.5625em;
						color: #ffdd6a;
						position: absolute;
						left: 1.1em;
						top: 6.8em;
					}

					.time {
						box-sizing: border-box;
						position: absolute;
						width: 13.625em;
						height: 3.625em;
						background: #fc5346;
						border-radius: 0.625em;
						padding: 1.375em 1.875em;
						color: #ffffff;
						top: 9.8125em;
						left: 16.25em;
						line-height: 100%;
					}

					.time2 {
						box-sizing: border-box;
						position: absolute;
						width: 12.625em;
						height: 3.625em;
						background: #fc5346;
						border-radius: 0.625em;
						padding: 1.375em 0.875em;
						color: #ffffff;
						top: 5.8125em;
						left: 16.25em;
						line-height: 100%;
					}
				}
			}
		}
	}
</style>
