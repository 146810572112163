import Vue from 'vue'
import App from './App.vue'
import axios from "axios"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
Vue.use(ElementUI);
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.config.productionTip = false
axios.defaults.baseURL = 'http://jc.hd557vr.com';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$axios = axios;

//全局过滤器，进行时间的格式化
//所谓的全局过滤器即使所有的vue实例都共享的
Vue.filter('dateFormat', function(dateStr, pattern = "") {
    //根据给定的时间字符串，得到特定的时间
    var dt = new Date(dateStr * 1000)
        //yyy---mm-dd
    var y = dt.getFullYear() //得到年份
    var m = dt.getMonth() + 1 //得到月份
    var d = dt.getDate() //得到日期
    if (m < 10) {
        m = '0' + m
    }
    if (d < 10) {
        d = '0' + d
    }
    // return y + '-' + m + '-' + d
    if (pattern.toLowerCase() === "yyy-mm-dd") {
        return `${y}-${m}-${d}`
    } else {
        var hh = dt.getHours() //得到时
        var mm = dt.getMinutes() //得到分
        if (mm < 10) {
            mm = '0' + mm
        }
        var ss = dt.getSeconds() //得到秒
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')