<template>
	<div id="notice">
		<el-dialog title="消息通知" :visible.sync="detail_dialog_show" width="780px">
			<div id="" style="margin-bottom: 2.5em;padding: 0 5em 0;">
				<div class="title">{{detail_lists.title}}</div>
				<div class="time">
					{{detail_lists.create_time}}
				</div>
				<div v-html="detail_lists.content">
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="detail_dialog_show = false">确 定
				</el-button>
			</span>
		</el-dialog>
		<div class="toptabs">
			<div id="yuyue">
				<div class="topbox">
					<el-input suffix-icon="el-icon-search" class="search" v-model="searchs.keyword"
						placeholder="请输入关键字">
					</el-input>
					<el-date-picker class="datepicker" value-format="yyyy-MM-dd" v-model="value1" type="daterange"
						range-separator="→" start-placeholder="开始日期" end-placeholder="结束日期" @change="xianshiriqi">
					</el-date-picker>
					<el-button class="searchbtn" @click="search">搜索</el-button>
				</div>
				<div class="flex">
					<div class="table">
						<el-table :data="tableData" stripe style="width: 100%;height: 39.0714285em;"
							:header-cell-style="{fontFamily:'Source Han Sans CN',fontSize:' 14px',border:'none',backgroundColor:'#FAFAFA',height:'3.857142857em',color:'#333333'}"
							:cell-style="{ fontSize:' 14px',color:'#333333',border:'none'}">
							<el-table-column prop="title" label="标题" width="400">
							</el-table-column>
							<el-table-column label="内容">
								<template slot-scope="scope">
									<div v-html="scope.row.content"></div>
								</template>
							</el-table-column>
							<el-table-column prop="create_time" label="时间" width="200">
							</el-table-column>
							<el-table-column width="120" label="操作">
								<template slot-scope="scope">
									<el-button size="mini" @click="handleEdit(scope.row.id)">查看详情</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="fenye" style="text-align: center;">
						<el-pagination :hide-on-single-page="true" prev-text="上一页" next-text="下一页" :background="true"
							layout="prev, pager, next" :total="totalPage" @current-change="search" @prev-click="search"
							@next-click="search">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				totalPage: 0,
				radio1: "个人",
				value1: '',
				centerDialogVisible: false,
				input: "",
				activeName: '0',
				searchs: {
					keyword: '', //会员名称、手机号、卡号
					type: '0', //0个人  1全部
					operation_type: '1', //操作类型 1补卡 2充值 3赠送 4预约 5绑卡 6换卡 7挂失
					start: '', //开始时间
					end: '', //结束时间
					page: '1', //页数
					limit: '7', //每页显示条数
				},
				tableData: [],
				detail_dialog_show: false,
				detail_lists: {}
			}
		},
		mounted() {
			this.search()
		},
		methods: {
			handleEdit(index) {
				console.log(index);
				this.give_cardevent(index)
			},
			changegender(e) {
				if (e == "个人") {
					this.searchs.type = 0
				} else {
					this.searchs.type = 1
				}
				this.search()
			},
			handleClick(tab, event) {
				this.search()
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			xianshiriqi(e) {
				console.log(e)
				if (e == null) {
					this.searchs.start = ''
					this.searchs.end = ''
				} else {
					this.searchs.start = e[0]
					this.searchs.end = e[1]
				}
				this.search()
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 详情
			give_cardevent(e) {
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				that.$axios.get('messagedetail', {
						headers: {
							"token": token,
						},
						params: {
							"id": e
						}
					}).then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.detail_lists = res.data.data
							that.detail_dialog_show = true
							// that.$message({
							// 	showClose: true,
							// 	message: res.data.msg,
							// 	type: 'success'
							// });
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							that.$message({
								showClose: true,
								message: res.data.msg + '需要重新登陆',
								type: 'error'
							});
							sessionStorage.removeItem("token")
							that.$router.push("/")
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						}
					})
					.catch((error) => {
						console.log(error) //  错误处理 相当于error
					})
			},
			search(e) {
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				if (e == '' || e == null || e == undefined) {
					that.$axios.get('message', {
							headers: {
								"token": token,
							},
							params: that.searchs
						}).then((res) => {
							console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								that.tableData = res.data.data.pageList
								that.totalPage = res.data.data.totalRow
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
							} else if (res.data.code == -1) {
								that.$message({
									showClose: true,
									message: res.data.msg + '需要重新登陆',
									type: 'error'
								});
								sessionStorage.removeItem("token")
								that.$router.push("/")
							} else {
								that.$message({
									showClose: true,
									message: res.data.msg,
									type: 'error'
								});
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				} else {
					that.searchs.page = e
					that.$axios.get('message', {
							headers: {
								"token": token,
							},
							params: that.searchs
						}).then((res) => {
							console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								that.tableData = res.data.data.pageList
								that.totalPage = res.data.data.totalRow
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
							} else if (res.data.code == -1) {
								that.$message({
									showClose: true,
									message: res.data.msg + '需要重新登陆',
									type: 'error'
								});
								sessionStorage.removeItem("token")
								that.$router.push("/")
							} else {
								that.$message({
									showClose: true,
									message: res.data.msg,
									type: 'error'
								});
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				}
			}
		}
	}
</script>

<style lang="less">
	#notice {
		background-color: #fff;
		border: 1px solid #EEEEEE;
		margin: 1.875em 2.5em 0 2.5em;
		border-radius: 1.25em;

		.el-dialog {
			border-radius: 0.1875em;

			.title {
				font-size: 24px;
				text-align: center;
				margin: 0.6666666666666665em 0 0.8333333333333333em 0;
			}

			.time {
				font-weight: bold;
				margin-bottom: 1em;
			}

			.el-dialog__body {
				padding: unset;
			}

			.el-dialog__title {
				font-size: 16px;
			}

			.el-dialog__headerbtn .el-dialog__close {
				color: #333333;
			}

			.el-dialog__header {
				border-bottom: 1px solid #eeeeee;
			}

			.el-dialog__footer {
				text-align: center;
				padding-top: unset;
				padding-bottom: 1.875em;

				.dialog-btn {
					width: 7.714285em;
					height: 2.5714285em;
					background: #109AFF;
					border-radius: 1.285714285em;
					line-height: 0;

					span {
						color: white;
						font-size: 14px;
					}
				}
			}
		}

		.toptabs {
			.topbox {
				padding: 0 0 2.142857em 0;
				margin-top: 1.875em;

				.datepicker {
					width: 17.375em;
					margin-left: 2.5em;
					background-color: #F2F4FF;

					.el-range-input {
						background-color: #F2F4FF;
					}
				}

				.searchbtn {
					width: 5.714285em;
					height: 2.85714285em;
					background: #FFFFFF;
					border: 1px solid #1890FF;
					border-radius: 1.428571em;
					margin-left: 2.5em;
					padding: 0.92857em 1.8571428em;

					span {
						font-size: 14px;
						color: #1890FF;
					}
				}

				.search {
					font-size: 14px;
					width: 25.0714285em;
					height: 2.85714285em;
					margin-top: 0.714em;

					.el-input__inner {
						width: 25.0714285em;
						height: 2.85714285em;
						border-radius: 0.35714em !important;
						background-color: #F2F4FF;
						border: none;
						color: #A0A2AB;
					}

					.el-input__suffix {
						margin-right: 1.25em;
					}

					.el-select__caret {
						line-height: 2.49999em !important;
					}
				}
			}

			.el-tabs__active-bar {}

			.el-select__caret {
				line-height: 2.49999em !important;
			}

			.el-tabs__header {
				margin-bottom: 1.875em;
			}

			.roundblue {
				display: inline-block;
				vertical-align: middle;
				width: 0.625em;
				height: .625em;
				background: #FFFFFF;
				border: 0.125em solid #1890FF;
				border-radius: 50%;
				margin-right: 0.375em;
			}

			.el-tabs__item {
				font-size: 16px;
				height: 3.625em;
				line-height: 3.75em;
			}

			.el-tabs__content {
				height: 100%;
			}

			.el-tabs__nav {
				margin-left: 3.75em;
			}

			// 通用底部按钮样式
			.btnbox {
				text-align: center;
				padding: 1.6875em 0;

				span {
					font-size: 14px;
				}

				.zhuce {
					font-size: 14px !important;
					width: 7.7142em;
					height: 2.5714em;
					background: #109AFF;
					border-radius: 1.2857em;
					line-height: 0;
					color: #fff;
				}

				.zhuce2 {
					font-size: 14px !important;
					width: 11.714285em;
					height: 2.5714em;
					background: #F8F8F8;
					border: 1px solid #EEEEEE;
					opacity: 1;
					line-height: 0;
					border-radius: 1.285714em;
				}

				.el-button+.el-button {
					margin-left: 2.14285em;
				}
			}

			// 通用表单样式
			.zhucehuiyuan {
				width: 57.5em;
				padding-bottom: 5em;
				padding-left: 2.5em;

				.title {
					margin-bottom: 1.375em;
				}

				.sex {
					width: 5.75em;
					border-radius: unset !important;
					margin-right: 0.8125em;
				}

				/* border-bottom: 1px solid #EEEEEE; */
				.el-input {
					width: 29.9999em;
					height: 2.4285em;
				}

				.el-input__inner {
					height: 2.4285em;
					background-color: #F2F4FF;
					line-height: 2.4285em;
					padding: 0 0.714285em;
				}

				.el-input__suffix {
					margin-right: 1.25em;
				}

				.el-form-item__content {
					line-height: 2.42857em;
				}

				.el-radio.is-bordered+.el-radio.is-bordered {
					margin-left: unset;
				}

				.flex {
					display: flex;
					flex-wrap: wrap;

					.hei {
						margin-right: 5em;
					}
				}
			}

			// 预约
			#yuyue {
				font-size: 0.875em;
				// padding-left: 4.28571em;
				padding: 0 4.28571em;

				.flex {
					// width: 101.57142857em;
					display: flex;
					// margin-top: 2.85714285em;
					justify-content: center;
					flex-direction: column;

					.fenye {
						margin-bottom: 14.5714285em;

						.el-pager .number {
							font-size: 14px;
							border: 1px solid #DDDDDD;
							background-color: #fff;
							color: #525B6C;
						}

						.active {
							color: #fff !important;
							background-color: #109AFF !important;
						}

						.more {
							background-color: #fff;
						}

						.btn-next,
						.btn-prev {
							background-color: #fff;
							border: 1px solid #DDDDDD;
							padding-left: 0.499999em;
							padding-right: 0.499999em;
						}
					}

					#danxuan {
						.el-radio-button {
							background-color: #F2F4FF;

							.el-radio-button__inner {
								width: 5.714285em;
								height: 2.14285em;
								line-height: 0.25em;
							}
						}
					}

					.table {
						// border: 1px solid #DDDDDD;
						font-size: 14px !important;
						margin: 1.4285714285em 0 4.285714285em 0;

						.el-table__header {
							font-size: 14px;
						}

						.el-table__body {
							font-size: 14px;
						}
					}

					.hei {
						margin-right: 5em;
					}

					.shufengexian {
						margin: unset;
						margin-right: 8.125em;
						height: 36.0625em;
						background-color: #eeeeee;
					}

					.shufengexianguashi {
						margin: unset;
						margin-right: 8.125em;
						height: 25.375em;
						background-color: #eeeeee;
					}

					.imgbox {
						position: relative;

						img {
							width: 24.125em;
							height: 15.3125em;
						}

						.time {
							box-sizing: border-box;
							position: absolute;
							width: 12.625em;
							height: 3.625em;
							background: #FC5346;
							border-radius: 0.625em;
							padding: 1.375em 1.875em;
							color: #ffffff;
							top: 9.8125em;
							left: 16.25em;
							line-height: 100%;
						}
					}
				}

				.zhucehuiyuan {
					width: 42.5em;
					padding-left: unset;

					.el-form-item__label {
						line-height: 1em;
						margin-bottom: 1em;
					}
				}

				.fengexian {
					margin: 0 0 2.5em !important;
					background-color: #EEEEEE;
				}
			}
		}
	}
</style>
