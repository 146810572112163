<template>
	<div id="login">
		<div class="loginbox">
			<div class="title">登录平台账号</div>
			<div class="dayuange">
				<el-input placeholder="请输入账号" prefix-icon="el-icon-user" v-model="account">
				</el-input>
			</div>
			<div class="dayuange">
				<el-input placeholder="请输入密码" prefix-icon="el-icon-unlock" v-model="password">
				</el-input>
			</div>
			<div class="dayuange">
				<Verify :codeLength="5" @success="alert('success')" @error="alert2()" width="198px" :type="1">
				</Verify>
			</div>
		</div>
	</div>
</template>

<script>
	import Verify from 'vue2-verify'

	export default {
		data() {
			return {
				account: "",
				password: ""
			}
		},
		methods: {
			alert(text) {
				const that = this
				console.log(text)
				that.$axios.post('login', {
						phone: that.account,
						password: that.password,
					}, ).then((res) => {
						// console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'success'
							});
							sessionStorage.setItem('token', JSON.stringify(res.data.token))
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.$router.push("/home")
						} else if (res.data.status == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						}
					})
					.catch((error) => {
						console.log(error) //  错误处理 相当于error
					})
			},
			alert2(text) {
				console.log(text)
				this.$message({
					showClose: true,
					message: '验证码错误，请重新输入',
					type: 'error'
				});
			}
		},
		components: {
			Verify
		}
	}
</script>

<style lang="less">
	html {
		height: 100%;

	}

	body {
		height: 100%;
	}

	#login {
		width: 100%;
		height: 100%;
		background-image: url("../assets/loginbgc.png");
		background-position: center;
		background-size: 100% auto;
		background-repeat: no-repeat;
		position: relative;

		.loginbox {
			position: absolute;
			background-color: #fff;
			text-align: center;
			box-sizing: border-box;
			width: 30em;
			right: 46em;
			top: 15em;
			padding: 2em 3em 7em;
			color: #fff;
			background: rgba(255, 240, 245, .4);
			border-radius: 0.5em;

			.dayuange {
				margin: 1em 0;
			}

			.title {
				font-size: 25px;
				letter-spacing: 3px;
			}

			.cerify-code-panel {
				display: flex;
				align-items: flex-end;
			}

			.verify-code {
				margin-bottom: unset;
				height: 40px !important;
				line-height: 40px !important;
				margin-right: 1em;
			}

			.verify-change-code {
				color: #fff;
			}

			.verify-code-area {
				float: unset;
			}

			.varify-input-code {}

			.verify-btn {
				width: 380px;
				height: 40px;
				border-radius: 5px;
				margin: 2em auto;
				cursor: pointer;
			}
		}
	}
</style>
