<template>
	<div class="index">
		<div style="display: flex;">
			<div class="pubcard vip">
				<div id="">
					<div class="title">会员数据</div>
					<div class="tabchange">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane v-for="item,index in cardInfo" :key="index+'a'" :label="item.cardname"
								:name="index+''">
								<div class="imgbox">
									<img :src="item.img">
									<div class="time">
										<span
											style="font-size: 0.875em;line-height: 0.785714em;">已开通：{{item.count}}人</span>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
				<div id="roundtongji"></div>
			</div>
			<div class="pubcard square">
				<div class="title">
					业务办理
				</div>
				<div id="" style="display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;">
					<div v-for="item,index in yewubanlilists" :key="index" :class="'yewubanli'+(` yewubanli`+index)"
						@click="todetail(item.page_url,item.index)">
						<div class="yewubanliflex">
							<span class="name">{{item.name}}</span>
							<img :src="item.imgurl" alt="" class="yewubanliimg">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="display: flex;justify-content: space-between;margin-bottom: 1.875em;">
			<div class="pubcard fenye">
				<div class="titlebox">
					<div class="title">贵宾使用记录</div>
					<el-pagination :page-size="5" :background="true" layout="prev, pager, next" :total="guibintotal"
						@current-change="vipnote" @prev-click="vipnote" @next-click="vipnote">
					</el-pagination>
				</div>
				<div class="fenyetable">
					<el-table stripe
						:header-cell-style="{fontFamily:'Source Han Sans CN',fontSize:' 14px',border:'none',backgroundColor:'#FAFAFA',height:'3.857142857em',color:'#333333'}"
						:cell-style="{ fontSize:' 14px',color:'#333333',height:'54px',padding:'unset'}"
						:data="guibinlists" style="width: 100%;font-size: 14px;">
						<el-table-column prop="real_name" label="会员姓名" width="140">
						</el-table-column>
						<el-table-column prop="phone" label="联系电话" width="201">
						</el-table-column>
						<el-table-column prop="create_time" label="预约时间" width="172">
						</el-table-column>
						<el-table-column prop="name" label="操作员">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="pubcard fenye">
				<div class="titlebox">
					<div class="title">贵宾使用情况</div>
					<!-- <el-radio-group :border="true" size="small" v-model="radio1" @change="gerenchange">
						<el-radio-button label="个人"></el-radio-button>
						<el-radio-button label="全体"></el-radio-button>
					</el-radio-group> -->
				</div>
				<div id="" style="display: flex;align-items: stretch;">
					<div id="squaretongji">

					</div>
				</div>
			</div>
		</div>
		<div style="display: flex;justify-content: space-between;margin-bottom: 3.4375em;">
			<div class="pubcard fenye">
				<div class="titlebox">
					<div class="title">业务办理记录</div>
					<el-pagination :page-size="5" :pager-count="5" :background="true" layout="prev, pager, next"
						:total="yewutotal" @current-change="businessnote" @prev-click="businessnote"
						@next-click="businessnote">
					</el-pagination>
				</div>
				<div class="fenyetable">
					<el-table stripe
						:header-cell-style="{fontFamily:'Source Han Sans CN',fontSize:' 14px',border:'none',backgroundColor:'#FAFAFA',height:'3.857142857em',color:'#333333'}"
						:cell-style="{ fontSize:' 14px',color:'#333333'}" :data="yewulists"
						style="width: 100%;font-size: 14px;">
						<el-table-column prop="real_name" label="会员姓名" width="140">
						</el-table-column>
						<el-table-column prop="phone" label="联系电话" width="131">
						</el-table-column>
						<el-table-column prop="type" label="业务项目" width="120">
						</el-table-column>
						<el-table-column prop="create_time" label="办理时间" width="201">
						</el-table-column>
						<el-table-column prop="name" label="操作员">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="pubcard fenye">
				<div class="titlebox">
					<div class="title">财务报表</div>
					<!-- <el-radio-group :border="true" size="small" v-model="radio2" @change="caiwubaobiao">
						<el-radio-button label="当月"></el-radio-button>
						<el-radio-button label="累计"></el-radio-button>
					</el-radio-group> -->
				</div>
				<div id="" style="display: flex;align-items: stretch;">
					<div id="squaretongji2">

					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import * as echarts from 'echarts';

	export default {
		data() {
			return {
				guibintotal: 20,
				radio1: "个人",
				cardInfo: [],
				piechart: [],
				vipuseday: "",
				vipusemonth: "",
				vipusetotal: "",
				businessaddcard: '',
				businessadd: '',
				businesstotal: '',
				yewutotal: 20,
				radio2: "当月",
				activeName: '0',
				yewubanlilists: [{
						name: "会员列表",
						imgurl: require("../assets/贵宾室.png"),
						page_url: "http://jichang.hd557vr.cn/index.php/admin/user/index",
						index: "1"
					}, {
						name: "财务报表",
						imgurl: require("../assets/会员注册.png"),
						page_url: "http://jichang.hd557vr.cn/index.php/admin/Financial/index",
						index: "0"
					}, {
						name: "业务办理记录",
						imgurl: require("../assets/业务办理.png"),
						page_url: "http://jichang.hd557vr.cn/index.php/admin/OperationRecords/index",
						index: "0"
					}, {
						name: "开卡统计",
						imgurl: require("../assets/操作记录.png"),
						page_url: "http://jichang.hd557vr.cn/index.php/admin/OpenCardStatistical/vipOpenCard",
						index: "1"
					},
					{
						name: "消息管理",
						imgurl: require("../assets/数据统计.png"),
						page_url: "http://jichang.hd557vr.cn/index.php/admin/SystemMessage/index",
						index: "5"
					},
				],
				yewulists: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				guibinlists: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}]
			}
		},
		methods: {
			todetail(e, i) {
				// this.$router.push({
				// 	name: e,
				// 	params: {
				// 		index: i
				// 	}
				// })
				window.location.href = e;
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			getroundtongji() {
				const that = this
				// var charts = that.piechart
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(document.getElementById('roundtongji'));
				// 绘制图表
				myChart.setOption({
					tooltip: {
						trigger: 'item',
						show: true
					},
					legend: {
						orient: 'vertical',
						left: '0',
						top: "center",
						icon: "circle",
						// 分别设置四个方向的内边距
						padding: [
							0, // 上
							0, // 右
							0, // 下
							0, // 左
						]
					},
					series: [{
						bottom: "0%",
						name: '现有绑卡会员人数',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: that.piechart
					}],
					grid: {
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						containLabel: true
					},
				});
			},
			getsquaretongji() {
				const that = this
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(document.getElementById('squaretongji'));
				// 绘制图表
				myChart.setOption({
					xAxis: {
						type: 'category',
						data: ['今日人数', '当月累计', '累计人数']
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: [{
							value: that.vipuseday,
							itemStyle: {
								color: '#5B8FF9'
							}
						}, {
							value: that.vipusemonth,
							itemStyle: {
								color: '#FC5346'
							}
						}, {
							value: that.vipusetotal,
							itemStyle: {
								color: '#FCD034'
							}
						}, ],
						type: 'bar',
						itemStyle: { //上方显示数值
							normal: {
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
								}
							}
						}
					}],
					grid: {
						top: '30',
						left: '0',
						right: '0',
						bottom: '0',
						containLabel: true
					},
				});
			},
			getsquaretongji2() {
				const that = this
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(document.getElementById('squaretongji2'));
				// 绘制图表
				myChart.setOption({
					xAxis: {
						type: 'category',
						data: ['开卡', '充值', '总计']
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: [{
							value: that.businessaddcard,
							itemStyle: {
								color: '#8E4CF1'
							}
						}, {
							value: that.businessadd,
							itemStyle: {
								color: '#FF843C'
							}
						}, {
							value: that.businesstotal,
							itemStyle: {
								color: '#07C2E8'
							}
						}, ],
						type: 'bar',
						itemStyle: { //上方显示数值
							normal: {
								label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: 'black',
										fontSize: 16
									}
								}
							}
						}
					}],
					grid: {
						top: '30',
						left: '0',
						right: '0',
						bottom: '0',
						containLabel: true
					},
				});
			},
			// 首页会员数据
			getindexdata() {
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				that.$axios.get('bakmemberData', {
						headers: {
							"token": token
						}
					}).then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
							that.cardInfo = res.data.data.cardInfo
							that.piechart = JSON.parse(res.data.data.pieChart)
							this.getroundtongji()

						} else if (res.data.code == -1) {
							// that.$message({
							// 	showClose: true,
							// 	message: res.data.msg + '需要重新登陆',
							// 	type: 'error'
							// });
							sessionStorage.removeItem("token")
							that.$router.push("/")
						}
					})
					.catch((error) => {
						console.log(error) //  错误处理 相当于error
					})
			},
			// 首页会员数据
			vipnote(e) {
				console.log("页数，", e)
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				if (e == "" || e == null || e == undefined) {
					that.$axios.get('bakvip/room/use/records', {
							headers: {
								"token": token
							},
							params: {
								"page": 1,
								limit: 5
							}
						}).then((res) => {
							// console.log(res);
							// //  处理成功的函数 相当于success
							if (res.data.code == 200) {
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								that.guibintotal = res.data.data.totalRow
								that.guibinlists = res.data.data.pageList
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				} else {
					that.$axios.get('bakvip/room/use/records', {
							headers: {
								"token": token
							},
							params: {
								"page": e,
								limit: 5
							}
						}).then((res) => {
							// console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								that.guibintotal = res.data.data.totalRow
								that.guibinlists = res.data.data.pageList
							} else if (res.data.code == -1) {
								that.$message({
									showClose: true,
									message: res.data.msg + '需要重新登陆',
									type: 'error'
								});
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				}
			},
			// 首页会员数据
			businessnote(e) {
				console.log("页数，", e)
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				if (e == "" || e == null || e == undefined) {
					that.$axios.get('bakbusiness/handle/records', {
							headers: {
								"token": token
							},
							params: {
								"page": 1,
								limit: 5
							}
						}).then((res) => {
							// console.log(res);
							// //  处理成功的函数 相当于success
							if (res.data.code == 200) {
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								that.yewutotal = res.data.data.totalRow
								that.yewulists = res.data.data.pageList
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				} else {
					that.$axios.get('bakbusiness/handle/records', {
							headers: {
								"token": token
							},
							params: {
								"page": e,
								limit: 5
							}
						}).then((res) => {
							// console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								that.yewutotal = res.data.data.totalRow
								that.yewulists = res.data.data.pageList
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				}
			},
			// 首页会员数据
			vipuse(e) {
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				if (e == "" || e == null || e == undefined) {
					that.$axios.get('bakvip/room/use/histogram', {
							headers: {
								"token": token
							},
							params: {
								type: 1
							}
						}).then((res) => {
							// console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								that.vipuseday = res.data.data.todayCount
								that.vipusemonth = res.data.data.monthCount
								that.vipusetotal = res.data.data.totalCount
								this.getsquaretongji()
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				} else {
					that.$axios.get('bakvip/room/use/histogram', {
							headers: {
								"token": token
							},
						}).then((res) => {
							// console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								that.vipuseday = res.data.data.todayCount
								that.vipusemonth = res.data.data.monthCount
								that.vipusetotal = res.data.data.totalCount

								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								this.getsquaretongji()
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				}
			},
			businessuse(e) {
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				if (e == "" || e == null || e == undefined) {
					that.$axios.get('bakfinancial/statements', {
							headers: {
								"token": token
							},
							params: {
								type: 1
							}
						}).then((res) => {
							// console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								that.businessaddcard = res.data.data.openCard
								that.businessadd = res.data.data.recharge
								that.businesstotal = res.data.data.total
								this.getsquaretongji2()
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				} else {
					that.$axios.get('bakfinancial/statements', {
							headers: {
								"token": token
							},
						}).then((res) => {
							// console.log(res);
							//  处理成功的函数 相当于success
							if (res.data.code == 200) {
								that.businessaddcard = res.data.data.openCard
								that.businessadd = res.data.data.recharge
								that.businesstotal = res.data.data.total
								// var token = JSON.parse(sessionStorage.getItem('token'))
								// console.log("获取的token：", token)
								this.getsquaretongji2()
							} else if (res.data.code == -1) {
								// that.$message({
								// 	showClose: true,
								// 	message: res.data.msg + '需要重新登陆',
								// 	type: 'error'
								// });
								sessionStorage.removeItem("token")
								that.$router.push("/")
							}
						})
						.catch((error) => {
							console.log(error) //  错误处理 相当于error
						})
				}
			},
			gerenchange(e) {
				console.log(e)
				if (e == "个人") {
					this.vipuse()
				} else {
					this.vipuse(1)
				}
			},
			caiwubaobiao(e) {
				console.log(e)
				if (e == "当月") {
					this.businessuse()
				} else {
					this.businessuse(1)
				}
			}
		},
		mounted() {
			this.getindexdata()
			this.vipnote()
			this.vipuse()
			this.businessnote()
			this.businessuse()
		}
	}
</script>
<style lang="less">
	.index {
		box-sizing: border-box;
		padding: 1.875em 1.5em 0 1.5em;

		.pubcard {
			background-color: #fff;
			border-radius: 1.25em;
			box-sizing: border-box;

			.title {
				/* 		display: inline-block;
		*/
				font-size: 1.25em;
				font-weight: bold;
				color: #333333;
				margin-bottom: 1.5em;
				letter-spacing: 0.0625em;
			}
		}

		.square {
			width: 30.3125em;
			flex-grow: 1;
			margin-bottom: 1.875em;
			box-sizing: border-box;
			padding: 2.5em 2.5em 0 2.5em;

			.yewubanli0 {
				background-color: #FBE7E8;
			}

			.yewubanli1 {
				background-color: #E6F2FF;
			}

			.yewubanli2 {
				background-color: #F0E8FF;
			}

			.yewubanli3 {
				background-color: #D7EEE3;
			}

			.yewubanli4 {
				background-color: #E7E9F3;
			}

			.yewubanli {
				color: #333333;
				font-weight: 900;
				width: 11.875em;
				margin: 0 0 1.875em 0;
				border-radius: 0.3125em;
				overflow: hidden;
				font-family: Source Han Sans CN;
				cursor: pointer;

				.yewubanliflex {
					box-sizing: border-box;
					padding: 2em 1.5em;
					height: 5em;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				.name {
					display: inline-block;
					vertical-align: middle;
				}

				.yewubanliimg {
					width: 1.625em;
					height: 1.625em;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		.vip {
			width: 70.375em;
			margin: 0 1.875em 1.875em 0;
			padding: 2.5em 3.75em;
			display: flex;

			#roundtongji {
				display: inline-block;
				box-sizing: border-box;
				flex-grow: 1;
				height: 23.375em;
				text-align: center;
			}

			.tabchange {
				width: 27.625em;
				margin-right: 8.375em;

				.imgbox {
					position: relative;

					img {
						width: 24.125em;
						height: 15.3125em;
					}

					.time {
						box-sizing: border-box;
						position: absolute;
						width: 10.375em;
						height: 3.625em;
						background: #FC5346;
						border-radius: 0.625em;
						padding: 1.375em 1.875em;
						color: #ffffff;
						top: 9.8125em;
						left: 16.25em;
						line-height: 100%;
					}
				}

				img {}

				.el-tabs__nav-wrap::after {
					display: none;
					background-color: none !important;
				}

				.el-tabs__header {
					margin-bottom: 1.875em;
				}

				.el-tabs__item {
					color: #A0A2AB;
					font-family: Source Han Sans CN;
				}

				.is-active {
					color: #333333 !important;
					font-weight: 400;
				}
			}
		}

		.fenye {
			width: 50.375em;
			padding: 2.5em 3.75em;

			#squaretongji {
				flex-grow: 1;
				height: 20.0625em;
			}

			#squaretongji2 {
				flex-grow: 1;
				height: 20.0625em;
			}


			.fenyetable {
				font-size: 0.875em !important;
			}

			.titlebox {
				display: flex;
				justify-content: space-between;
			}

			.el-pager .number {
				font-size: 14px;
				border: 1px solid #DDDDDD;
				background-color: #fff;
				color: #525B6C;
			}

			.active {
				color: #fff !important;
				background-color: #109AFF !important;
			}

			.more {
				background-color: #fff !important;
			}

			.btn-next,
			.btn-prev {
				background-color: #fff;
				border: 1px solid #DDDDDD;
				padding-left: 0.499999em;
				padding-right: 0.499999em;
			}
		}

		/* padding-top: 1.875em; */
	}
</style>
