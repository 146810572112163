<template>
	<div id="app">
		<el-dialog title="个人信息" :visible.sync="personal_info" width="580px">
			<div id="" style="margin-bottom: 2.5em;box-sizing: border-box;padding: 1em 5em 0;">
				<el-form label-position="left" :model="personal_info_lists" class="demo-form-inline">
					<el-form-item label="姓名">
						<el-input disabled v-model="personal_info_lists.name"></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<el-input disabled v-model="personal_info_lists.gender==1?'男':'女'"></el-input>
					</el-form-item>
					<el-form-item label="手机号">
						<el-input disabled v-model="personal_info_lists.phone"></el-input>
					</el-form-item>
					<el-form-item label="身份证号">
						<el-input disabled v-model="personal_info_lists.Id_card"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="personal_info = false">确 定
				</el-button>
			</span>
		</el-dialog>
		<el-dialog title="修改密码" :visible.sync="edit_password" width="580px">
			<div id="" style="text-align: center;margin-bottom: 2.5em;box-sizing: border-box;padding: 1em 5em 0;">
				<el-form label-position="left" :model="edit_password_lists" class="demo-form-inline">
					<el-form-item label="用户">
						<el-input disabled v-model="personal_info_lists.name"></el-input>
					</el-form-item>
					<el-form-item label="旧密码">
						<el-input v-model="edit_password_lists.oldPassword"></el-input>
					</el-form-item>
					<el-form-item label="新密码">
						<el-input v-model="edit_password_lists.newPassword"></el-input>
					</el-form-item>
					<el-form-item label="确认密码">
						<el-input v-model="edit_password_lists.confirmPassword"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="dialog-btn" type="primary" @click="edit_password_event">确 定
				</el-button>
				<el-button class="dialog-btn-cancel" @click="edit_password = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-menu :default-active="activeIndex" active-text-color="#1890FF" class="leftnavlists" @select="activeIndexs">
			<div class="logobox" style="">
				<img src="../assets/home-left-top-logo.png" style="width: 2.5em;height: 2.5em;margin: 0.625em 0;">
			</div>
			<el-menu-item v-for="(item,index) in leftnavlists" :key="index" @click="todetail(item.link,item.index)"
				:index="index.toString()">
				<img class="leftnavlistsimg" :src="activeIndex==index?item.aurl:item.url">
				<span slot="title">
					{{item.name}}
				</span>
			</el-menu-item>
		</el-menu>
		<div class="ouch">
			<div class="topnavlists">
				<div class="topflex">
					<div id="">
					</div>
					<!-- <el-input prefix-icon="el-icon-search" class="search" v-model="input" placeholder="输入搜索内容...">
					</el-input> -->
					<div class="userbox">
						<!-- <img src="https://img1.baidu.com/it/u=1749212012,1526735186&fm=26&fmt=auto&gp=0.jpg" alt=""> -->
						<!-- <span class="username">史森明</span>
						<i class="el-icon-arrow-down wunai"></i> -->
						<el-dropdown>
							<span class="el-dropdown-link">
								<span class="username">{{personal_info_lists.name}}</span>
								<i class="el-icon-arrow-down  wunai"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="personal_info=true">
									<img class="userinfoimg" src="../assets/个人信息.png" alt="">
									<span class="userinfotxt">个人信息</span>
								</el-dropdown-item>
								<el-dropdown-item @click.native="edit_password=true">
									<img class="userinfoimg" src="../assets/密码-粗线条.png" alt="">
									<span class="userinfotxt">修改密码</span>
								</el-dropdown-item>
								<el-dropdown-item divided style="text-align: center;">
									<span class="userinfotxt" @click='outs'>退出登录</span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
			<router-view></router-view>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				personal_info_lists: {},
				edit_password_lists: {
					oldPassword: '', //是	string	旧密码
					newPassword: '', //是	string	新密码
					confirmPassword: '', //是	string	确认密码
				},
				personal_info: false,
				edit_password: false,
				input: "",
				activeIndex: '0',
				leftnavlists: [{
						name: "首页",
						link: 'abouts',
						url: require("../assets/首页.png"),
						aurl: require("../assets/首页-蓝.png")
					}, {
						name: "业务办理",
						link: 'yewubanli',
						url: require("../assets/业务.png"),
						aurl: require("../assets/业务-蓝.png"),
					},
					// {
					// 	name: "开通会员",
					// 	link: '',
					// 	url: require("../assets/会员(1).png"),
					// 	aurl: require("../assets/会员-蓝.png"),
					// }, 
					{
						name: "操作记录",
						link: 'caozuojilu',
						url: require("../assets/操作日志.png"),
						aurl: require("../assets/操作日志-蓝.png"),
					},
					{
						name: "到期提醒",
						link: 'outtime',
						url: require("../assets/操作日志.png"),
						aurl: require("../assets/操作日志-蓝.png"),
					},
					{
						name: "消息通知",
						link: 'notice',
						url: require("../assets/消息.png"),
						aurl: require("../assets/消息-蓝.png"),
					},
				],

			}
		},
		methods: {
			edit_password_event() {
				const that = this
				const data = that.edit_password_lists
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log(this.ruleForm)
				that.$axios.post('editpassword',
						data, {
							headers: {
								"token": token
							}
						})
					.then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'success'
							});
							that.edit_password_lists = {
								oldPassword: '', //是	string	旧密码
								newPassword: '', //是	string	新密码
								confirmPassword: '', //是	string	确认密码
							}
							that.edit_password = false
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							// that.$message({
							// 	showClose: true,
							// 	message: res.data.msg + '需要重新登陆',
							// 	type: 'error'
							// });
							sessionStorage.removeItem("token")
							that.$router.push("/")
						} else if (res.data.code == 0) {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						}
					})
					.catch((error) => {
						console.log(error) //  错误处理 相当于error
					})
			},
			outs() {
				sessionStorage.removeItem("token")
				sessionStorage.removeItem("left_menu_index")
				sessionStorage.setItem('outtime_first', false)
				this.$router.push("/")
			},
			activeIndexs(e) {
				this.activeIndex = e
			},
			todetail(e, i) {
				this.$router.push({
					name: e,
					params: {
						id: i
					}
				})
			},
			get_personal_info() {
				const that = this
				var token = JSON.parse(sessionStorage.getItem('token'))
				console.log("保存的token：", token)
				that.$axios.get('personalinfo', {
						headers: {
							"token": token,
						}
						// ,
						// params: {
						// 	"card_id": that.give.cardtype,
						// 	"keyword": that.givesearchcontent,
						// }
					}).then((res) => {
						console.log(res);
						//  处理成功的函数 相当于success
						if (res.data.code == 200) {
							this.personal_info_lists = res.data.data
							// that.personal_info = true
							// var token = JSON.parse(sessionStorage.getItem('token'))
							// console.log("获取的token：", token)
						} else if (res.data.code == -1) {
							// that.$message({
							// 	showClose: true,
							// 	message: res.data.msg + '需要重新登陆',
							// 	type: 'error'
							// });
							sessionStorage.removeItem("token")
							that.$router.push("/")
						} else {
							that.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						}
					})
					.catch((error) => {
						console.log(error) //  错误处理 相当于error
					})
			}
		},
		watch: {
			$route() {
				console.log(this.$route.path)
				if (this.$route.path === '/abouts') {
					this.activeIndex = '0'
					sessionStorage.setItem('left_menu_index', JSON.stringify(this.activeIndex))
				} else if (this.$route.path === '/yewubanli') {
					this.activeIndex = '1'
					sessionStorage.setItem('left_menu_index', JSON.stringify(this.activeIndex))
				} else if (this.$route.path === '/caozuojilu') {
					this.activeIndex = '2'
					sessionStorage.setItem('left_menu_index', JSON.stringify(this.activeIndex))
				} else if (this.$route.path === '/notice') {
					this.activeIndex = '3'
					sessionStorage.setItem('left_menu_index', JSON.stringify(this.activeIndex))
				}
			}
		},
		mounted() {
			this.get_personal_info()
			if (sessionStorage.getItem("left_menu_index") == "" || sessionStorage.getItem("left_menu_index") == null ||
				sessionStorage.getItem("left_menu_index") == undefined) {} else {
				this.activeIndex = JSON.parse(sessionStorage.getItem('left_menu_index'))
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	#app {
		display: flex;
		width: 100%;
		// height: 100%;
		align-items: stretch;

		.el-dialog {
			border-radius: 0.1875em;

			.title {
				font-size: 14px;
				text-align: center;
				margin: 2.85714285em 0 1.4285714285em 0;
			}

			.el-dialog__body {
				padding: unset;

				.el-input.is-disabled .el-input__inner {
					color: black;
				}
			}

			.el-dialog__title {
				font-size: 16px;
			}

			.el-dialog__headerbtn .el-dialog__close {
				color: #333333;
			}

			.el-dialog__header {
				border-bottom: 1px solid #eeeeee;
			}

			.el-dialog__footer {
				text-align: center;
				padding-top: unset;
				padding-bottom: 1.875em;

				.dialog-btn {
					width: 7.714285em;
					height: 2.5714285em;
					background: #109AFF;
					border-radius: 1.285714285em;
					line-height: 0;

					span {
						color: white;
						font-size: 14px;
					}
				}

				.dialog-btn-cancel {
					width: 7.714285em;
					height: 2.5714285em;
					border-radius: 1.285714285em;
					line-height: 0;

					span {
						font-size: 14px;
					}

				}
			}
		}

		.ouch {
			flex-grow: 1;

			.topnavlists {
				box-sizing: border-box;
				height: 3.75em;
				border-left: none;
				background-color: #fff;
				padding: 0 3.75em;

				.topflex {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-top: 1.4375em;

					.userbox {
						display: flex;
						align-items: center;

						.userinfotxt {
							font-size: .875em;
							margin: 0 auto;
						}

						.userinfoimg {
							width: 1em;
							height: .875em;
							margin-right: .625em;
							display: inline-block;
							vertical-align: middle;
						}

						.username {
							font-size: 0.875em;
							margin-left: 0.4285em;
						}

						img {
							width: 1.875em;
							height: 1.875em;
							border-radius: 50%;
						}

						.wunai {
							font-size: 0.75em;
							margin-left: 4em;
						}
					}

					.search {
						font-size: 14px;
						width: 20em !important;
						height: 2.857em !important;
						margin-top: 0.714em;
					}

					.search .el-input__inner {
						border-radius: 1.25em !important;
						background-color: #F2F4FF;
						border: none;
						color: #A0A2AB;
					}
				}
			}
		}

		.leftnavlists {
			min-width: 9.9375em;
			box-sizing: border-box;
			width: 9.9375em;
			border-right: none !important;
			align-items: stretch;
			padding-left: 1.25em !important;

			.leftnavlistsimg {
				width: 0.9375em;
				height: 0.875em;
				margin-right: 0.5em;
			}

			.el-menu-item {
				height: 3.125em !important;
				line-height: 3.125em !important;
				margin-bottom: 0.5em;
				font-weight: 600;
			}

			.el-menu-item span {
				font-size: 0.875em !important;
			}

			.logobox {
				text-align: center;
				box-sizing: border-box;
				height: 3.75em;
			}

			.el-menu-item:focus,
			.el-menu-item:hover,
			.el-menu-item.is-active {
				background-color: #F2F4FF;
				border-radius: 0.625em 0 0 0.625em;
			}

		}
	}
</style>
